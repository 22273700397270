import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export interface IPollIntro {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  wasAcceptedPolicy: boolean;
}

export interface IPollInformation {
  aboutMe: string;
  gender: "Мужской" | "Женский" | "";
  birthday: Date | null;
  city: string | null;
  avatar: File | null;
  isAbroad: boolean;
  activity: {
    proffessional: number;
    romantic: number;
    chat: number;
  };
  interlocutorGender: {
    male: boolean;
    female: boolean;
  };
  interlocutorAge: {
    from: number;
    till: number;
  };
  socials: {
    telegram: string;
    vk: string;
    whatsapp: string;
    facebook: string;
    instagram: string;
  };
  isNotifyByEvents: boolean;
}

interface IPollSliceState {
  step: {
    current: number;
    max: number;
    min: number;
  };
  form: {
    intro: IPollIntro;
    hobbies: string[];
    interests: string[];
    needs: string[];
    connections: string[];
    information: IPollInformation;
  };
}

const initialState: IPollSliceState = {
  step: {
    current: 1,
    max: 6,
    min: 1,
  },
  form: {
    intro: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      wasAcceptedPolicy: false,
    },
    hobbies: [],
    interests: [],
    needs: [],
    connections: [],
    information: {
      aboutMe: "",
      gender: "",
      birthday: null,
      city: null,
      avatar: null,
      isAbroad: false,
      activity: {
        proffessional: 5,
        chat: 5,
        romantic: 2,
      },
      interlocutorGender: {
        male: false,
        female: false,
      },
      interlocutorAge: {
        from: 18,
        till: 56,
      },
      socials: {
        telegram: "",
        vk: "",
        whatsapp: "",
        facebook: "",
        instagram: "",
      },
      isNotifyByEvents: true,
    },
  },
};

type FormKey = keyof IPollSliceState["form"];

export const pollSlice = createSlice({
  name: "poll",
  initialState,
  reducers: {
    addForm<K extends FormKey>(
      state: IPollSliceState,
      action: PayloadAction<{
        data: IPollSliceState["form"][K];
        key: K;
      }>,
    ) {
      state.form[action.payload.key] = action.payload.data;
    },
    resetForm(state) {
      state.form = initialState.form;
      state.step = initialState.step;
    },
    addStep(state, action: PayloadAction<number>) {
      state.step.current = action.payload;
    },
    incrementStep(state) {
      state.step.current =
        state.step.current >= state.step.max
          ? state.step.max
          : state.step.current + 1;
    },
    decrementStep(state) {
      state.step.current =
        state.step.current <= state.step.max
          ? state.step.min
          : state.step.current - 1;
    },
  },
});

export const { addForm, addStep, resetForm, incrementStep, decrementStep } =
  pollSlice.actions;

export const selectStep = (state: RootState) => state.poll.step;
export const selectForm = (key: FormKey) => (state: RootState) =>
  state.poll.form[key];

export default pollSlice.reducer;
