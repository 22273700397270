import clsx from "clsx";

import { ContactSocials } from "src/pages/contacts/Cards/ContactCard/ContactSocials";
import { ContactHeader } from "src/pages/contacts/Cards/ContactCard/ContactHeader";
import { ContactBio } from "src/pages/contacts/Cards/ContactCard/ContactBio";
import { Image } from "src/content/Image";

import { IMessage } from "src/redux/features/bot/botSlice";
import HeartIcon from "./heart.svg";
import styles from "./ProfileCard.module.scss";

export default function ProfileCard({ data }: { data: IMessage }) {
  return (
    <div className={styles.ProfileCard}>
      <div className={styles.ProfileCard__Header}>
        <ContactHeader
          showStatus={false}
          data={{
            avatar: data.interlocutor?.avatar,
            gender: data.interlocutor?.gender,
            lastScores: [],
            latestMatch: {
              status: "NEW",
              createdAt: "",
            },
          }}
        />
        <ContactBio
          data={{
            firstName: data?.interlocutor?.firstName,
            lastName: data?.interlocutor?.lastName,
            birthday: data?.interlocutor.birthday,
            city: data?.interlocutor.city,
            isAbroad: data?.interlocutor.isAbroad,
          }}
        />
      </div>
      <div className={styles.ProfileCard__AboutMe}>
        {data.interlocutor?.personPolls?.aboutMe}
      </div>
      <div className={styles.ProfileCard__Tags}>
        {data.interlocutor?.personPolls?.hobbies?.map(({ id, name }) => (
          <div className={styles.ProfileCard__Tag} key={id}>
            {name}
          </div>
        ))}
      </div>
      <hr className={styles.ProfileCard__Divider} />
      <ContactSocials
        className={styles.ProfileCard__Socials}
        data={{ personSocials: data.interlocutor.personSocials }}
      />
      {data?.interlocutor?.personPolls?.communication?.romantic > 0 && (
        <div
          className={clsx(styles.RomanticInfo, styles.ProfileCard__Romantic)}
        >
          <Image
            className={styles.RomanticInfo__Icon}
            height={22}
            width={22}
            alt="heart"
            src={HeartIcon}
          />
          <div className={styles.RomanticInfo__Text}>
            Готов к романтическому знакомству
          </div>
        </div>
      )}
    </div>
  );
}
