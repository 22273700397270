import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";

import { Cropper, EventForm } from "src/content";
import { LayoutAccount } from "src/layouts";
import { BaseButton } from "src/components/base/BaseButton";
import { BaseImage } from "src/components/base/BaseImage";
import { Button } from "src/components/base/Button";

import {
  NewEventData,
  NewEventLink,
  NewEventType,
  useCreateEvent,
  useCreatorContacts,
  useEventStatuses,
  useUploadImage,
} from "src/api/hooks/eventsHooks";
import { CreatorCredsScheme, EventScheme } from "src/common/validation";
import { CloseEventModal } from "src/modals/CloseEventModal";
import { useModal } from "src/hooks/useModal";
import imageGopnikCreateEvent from "src/common/images/gopnik.png";
import styles from "./EventCreate.module.scss";
import useUploadCover from "src/hooks/useUploadCover";
import CreatogEventCredsModal, {
  CreatorCredsType,
} from "src/modals/CreatogEventCredsModal/CreatogEventCredsModal";
import { gerCurrentCostPolitic } from "src/api/hooks/getCurrentCostPolitic";

const EventCreatePage = () => {
  const navigate = useNavigate();
  const {
    uploadedCover,
    croppedCover,
    handleDeleteCropped,
    handleEditCover,
    handleUploadCover,
    handleUploadCropped,
    isOpenCropper,
    toggleCropper,
  } = useUploadCover();

  const {
    uploadedCover: uploadedCoverDetils,
    croppedCover: croppedCoverDetils,
    handleDeleteCropped: handleDeleteCroppedDetils,
    handleEditCover: handleEditCoverDetils,
    handleUploadCover: handleUploadCoverDetils,
    handleUploadCropped: handleUploadCroppedDetils,
    isOpenCropper: isOpenCropperDetils,
    toggleCropper: toggleCropperDetils,
  } = useUploadCover();

  // Form initialize
  const form = useForm<NewEventData>({
    defaultValues: {
      title: "",
      text: "",
      address: "",
      eventsLink: [
        { name: "", link: "" },
        { name: "", link: "" },
        { name: "", link: "" },
      ],
      isFree: false,
      eventLink: "",
      costPolitic: [
        {
          id: `${new Date()}`,
          name: "",
          price: undefined,
        },
      ],
    },
    resolver: yupResolver(EventScheme),
  });

  // Form state
  const errors = form.formState.errors;
  const state = form.watch();

  const formCreatorCreds = useForm<CreatorCredsType>({
    defaultValues: {
      creatorTg: "",
      creatorMobile: "",
    },
    resolver: yupResolver(CreatorCredsScheme),
  });

  // Form state handler
  const onHandleState = React.useCallback(
    (
      key: keyof NewEventData,
      value: string | NewEventLink[] | NewEventType[] | boolean,
    ) => {
      form.setValue(key, value, {
        shouldValidate: true,
      });
    },
    [],
  );

  const onHandleStateCreatorCreds = React.useCallback(
    (key: keyof CreatorCredsType, value: string) => {
      formCreatorCreds.setValue(key, value, {
        shouldValidate: true,
      });
    },
    [],
  );

  React.useEffect(() => {
    if (state.isFree) {
      form.reset({
        ...state,
        costPolitic: [
          {
            id: `${new Date()}`,
            name: "",
          },
        ],
      });
    }
  }, [state.isFree]);

  // Requests to api
  const { data: eventStatuses } = useEventStatuses();
  const { data: creatorData } = useCreatorContacts();
  const createEvent = useCreateEvent();
  const uploadImage = useUploadImage();

  const [isLoading, setIsLoading] = React.useState(false);

  // Event status id state
  const [eventStatusId, setEventStatusId] = React.useState<
    string | undefined
  >();

  useEffect(() => {
    if (!creatorData) return;
    formCreatorCreds.setValue("creatorMobile", creatorData.phone);
    formCreatorCreds.setValue("creatorTg", creatorData.tg);
  }, [creatorData]);

  // Form submit handler
  const onSubmitHandler = async (
    data: NewEventData,
    creatorCreds: CreatorCredsType,
  ) => {
    if (!eventStatusId) return;
    const formData = new FormData();
    try {
      setIsLoading(true);

      const currentIsFreeAndCostPolitic = gerCurrentCostPolitic(
        form.getValues("costPolitic"),
      );

      const createdEvent = await createEvent.mutateAsync({
        ...data,
        ...creatorCreds,
        eventStatusId,
        status: "MODERATION",
        ...currentIsFreeAndCostPolitic,
      });

      // If cropped image exists
      if (croppedCover) {
        formData.set("cover", croppedCover as Blob);
        await uploadImage.mutateAsync({
          id: createdEvent.id,
          formData,
          type: "Cover",
        });
        formData.delete("cover");
      }
      // If croppedDetails image exists
      if (croppedCoverDetils) {
        formData.set("cover", croppedCoverDetils as Blob);
        await uploadImage.mutateAsync({
          id: createdEvent.id,
          formData,
          type: "Details",
        });
        formData.delete("cover");
      }
      toast.success("Запись отправлена на модерацию");
      navigate(-1);
    } catch (e) {
      toast.error("Не удалось создать запись");
      console.error(e);
      navigate(-1);
    } finally {
      setIsLoading(false);
    }
  };

  //runs when the modal is filled in correctly with Creator creds
  const onSubmitCreatorCreds = (creatorCreds: CreatorCredsType) => {
    form.handleSubmit((data) => {
      onSubmitHandler(data, creatorCreds);
    })();
  };

  const publish = eventStatuses ? eventStatuses[0]?.id : "";
  const archive = eventStatuses ? eventStatuses[1]?.id : "";

  const { toggle, isShowing } = useModal();

  const { toggle: toggleCreatorCreds, isShowing: isShowingCreatorCreds } =
    useModal();

  return (
    <>
      <LayoutAccount
        title="Создать мероприятие"
        isArrowGoBack={true}
        onClick={toggle}
      >
        <div
          className={styles.EventCreatePage}
          onSubmit={(e) => {
            e.preventDefault();

            //if custom errors success => open creator creds modal
            form
              .getValues("costPolitic")
              .every(
                (value) =>
                  (value.name && value.price) || (!value.name && !value.price),
              ) && form.handleSubmit(toggleCreatorCreds)();
          }}
        >
          <form
            className={styles.EventCreatePage__form}
            id="event-create-form-id"
          >
            <EventForm
              onHandleState={onHandleState}
              onUploadCover={handleUploadCover}
              onDeleteCover={handleDeleteCropped}
              onEditCover={handleEditCover}
              croppedCover={croppedCover}
              onUploadCoverDetails={handleUploadCoverDetils}
              onDeleteCoverDetails={handleDeleteCroppedDetils}
              onEditCoverDetails={handleEditCoverDetils}
              croppedCoverDetails={croppedCoverDetils}
              errors={errors}
              state={state}
            />
            <div className={styles.EventCreatePage__buttons}>
              <Button
                className={styles.EventCreatePage__button}
                onClick={() => setEventStatusId(publish)}
                fullWidth={true}
                disabled={isLoading}
                variant="contained_nuar"
                form="event-create-form-id"
                type="submit"
              >
                Опубликовать
              </Button>
              <BaseButton
                className={styles.EventCreatePage__button}
                onClick={() => setEventStatusId(archive)}
                variant="base-text-btn"
                disabled={isLoading}
                form="event-create-form-id"
                type="submit"
              >
                Сохранить в архив
              </BaseButton>
            </div>
          </form>
          <BaseImage
            className={styles.EventCreatePage__image}
            src={imageGopnikCreateEvent}
            height="516"
            width="407.15"
          />
        </div>
      </LayoutAccount>
      <Cropper
        isOpen={isOpenCropper}
        toggle={toggleCropper}
        file={uploadedCover as File}
        onChange={handleUploadCropped}
        onCancel={toggleCropper}
        type={"Cover"}
      />
      <Cropper
        isOpen={isOpenCropperDetils}
        toggle={toggleCropperDetils}
        file={uploadedCoverDetils as File}
        onChange={handleUploadCroppedDetils}
        onCancel={toggleCropperDetils}
        type={"Details"}
      />
      <CloseEventModal isOpen={isShowing} toggle={toggle} />
      <CreatogEventCredsModal
        isOpen={isShowingCreatorCreds}
        toggle={toggleCreatorCreds}
        onClickSubmit={() => {
          formCreatorCreds.handleSubmit(onSubmitCreatorCreds)();
        }}
        state={formCreatorCreds.watch()}
        errors={formCreatorCreds.formState.errors}
        onHandleState={onHandleStateCreatorCreds}
      />
    </>
  );
};

export default EventCreatePage;
