import React from "react";

import { getFromStorage } from "src/common/local-storage";

export type UserStateType = {
  personId: string;
};

export const useJwtPayload = (): UserStateType => {
  // const [user, setUser] = React.useState<UserStateType | undefined>(undefined);

  const user = React.useMemo(() => {
    const token = getFromStorage<string>("ACCESS_TOKEN");

    if (!token) return { personId: null };

    const data = JSON.parse(window.atob(token?.split(".")[1]));

    return { personId: data.id };
  }, []);

  return user;
};
