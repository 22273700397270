export enum EventTypeEnum {
  "Все" = "Все",
  "Демо" = "Демо",
  "Встреча" = "Встреча",
  "Презентация" = "Презентация",
}

export enum EventStatusEnum {
  "Все" = "Все",
  "Опубликовано" = "Опубликовано",
  "Архив" = "Архив",
  "Завершено" = "Завершено",
}

export enum RoutesEnum {
  HOME = "/",
  SIGNIN = "/signin",
  SIGNUP = "/signup-form",
  SIGNOUT = "/signout",
  RESTORE = "/signin/reset-password",
  ACCOUNT = "/account",
  EVENTS = "/event-list",
  EVENTS_UPDATE = "/update_event",
  EVENTS_CREATE = "/create_event",
  CONTACTS = "/contacts",
  CHAT_BOT = "/chat-bot",
}

export enum QueriesEnum {
  ALL_EVENTS = "?tab=all-events",
  MY_EVENTS = "?tab=my-events",
}
