import { ClassAttributes, InputHTMLAttributes, useState } from "react";
import InputMask from "react-input-mask";
import clsx from "clsx";

import { BaseIcon } from "../BaseIcon";

import "./BaseInputSocials.scss";

type PropsType = InputHTMLAttributes<HTMLInputElement> & {
  variant: "TG" | "VK" | "WA" | "FB" | "IG";
  error?: boolean;
  className?: string;
};

function BaseInputSocials({
  className = "",
  variant,
  error,
  ...rest
}: PropsType) {
  return (
    <label
      className={clsx({
        BaseInputSocials: true,
        BaseInputSocials_required: error,
        [className]: !!className,
      })}
    >
      <div className="BaseInputSocials__icon-box">
        <BaseIcon
          icon={`SOCIALS_${variant}`}
          viewBox={"0 0 20 21"}
          className="BaseInputSocials__icon"
        />
      </div>
      <input className="BaseInputSocials__input" type="text" {...rest} />
    </label>
  );
}

export function BaseMaskedInput({
  className = "",
  variant,
  error,
  value,
  onChange,
  disabled = false,
}: {
  className?: string;
  variant: "WA";
  error: boolean;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}) {
  // const [phone, setPhone] = useState("");

  const handleChange = (e: any) => {
    let value = e.target.value;

    // Заменяем 8 в начале на +7
    if (value.startsWith("8")) {
      value = value.replace(/^8/, "+7");
    }

    onChange(value);
  };
  return (
    <label
      className={clsx({
        BaseInputSocials: true,
        BaseInputSocials_required: error,
        [className]: !!className,
      })}
    >
      <div className="BaseInputSocials__icon-box">
        <BaseIcon
          icon={`SOCIALS_${variant}`}
          viewBox={"0 0 20 21"}
          className="BaseInputSocials__icon"
        />
      </div>
      <InputMask
        mask="+7 (999) 999-99-99"
        value={value}
        onChange={handleChange}
        placeholder="+7 (___) ___-__-__"
        disabled={disabled}
      >
        {(
          inputProps: JSX.IntrinsicAttributes &
            ClassAttributes<HTMLInputElement> &
            InputHTMLAttributes<HTMLInputElement>,
        ) => (
          <input
            className="BaseInputSocials__input"
            {...inputProps}
            type="tel"
          />
        )}
      </InputMask>
    </label>
  );
}

export default BaseInputSocials;
