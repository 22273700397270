import { useState } from "react";
import { IAnswer } from "src/redux/features/bot/botSlice";

interface IReplyState {
  nextQuestionID: string;
  answerIDs: string[];
}

const initialState: IReplyState = {
  nextQuestionID: "",
  answerIDs: [],
};

export function useChatReply() {
  const [reply, setReply] = useState(initialState);

  const handleReply = (answer: IAnswer) => {
    const newReply: IReplyState = structuredClone(reply) as IReplyState;
    newReply.nextQuestionID = answer.nextQuestionID;

    switch (answer.variant) {
      case "MULTIPLE":
        reply.answerIDs.includes(answer.id)
          ? (newReply.answerIDs = newReply.answerIDs.filter(
              (id) => id !== answer.id,
            ))
          : newReply.answerIDs.push(answer.id);
        break;
      case "SINGLE":
        newReply.answerIDs.length = 0;
        newReply.answerIDs.push(answer.id);
        break;
      default:
        return;
    }

    setReply(newReply);
  };

  const clearReply = () => {
    setReply(initialState);
  };

  return {
    handleReply,
    clearReply,
    reply,
  };
}
