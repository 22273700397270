import { Navigate, Route, Routes } from "react-router-dom";

import { DefaultAccount } from "./layouts";
import { SignupRoutes } from "./pages/signup-routes";
import { SigninRoutes } from "./pages/signin-routes";
import { RoutePrivate } from "./components/protection/RoutePrivate";
import { ContactsPage } from "./pages/contacts";
import { Home } from "./pages/home";
import Signout from "./pages/signout";
import {
  MeetingsPage,
  AccountPage,
  EventsPage,
  EventUpdatePage,
  EventCreatePage,
  EventPage,
  EventAdminPage,
} from "./pages";
import { ChatBot } from "./pages/chat";

import { RoutePublic } from "./components/protection/RoutePublic";
import { RoutesEnum } from "./common/enums";

import { useScrollbarPadding } from "./hooks/useScrollbarPadding";

function App() {
  useScrollbarPadding();
  return (
    <Routes>
      <Route path="/">
        <Route path="/" element={<Home />} />
        <Route element={<DefaultAccount />}>
          <Route path={"/event-list"} element={<EventsPage />} />
          <Route element={<RoutePrivate />}>
            <Route path="/update_event/:id" element={<EventUpdatePage />} />
            <Route path="/create_event" element={<EventCreatePage />} />
          </Route>
        </Route>
        <Route element={<DefaultAccount isWhiteHeader />}>
          <Route path={"/event-list/events/:id"} element={<EventPage />} />
          <Route
            path={"/event-list/adminEvents/:id"}
            element={<EventAdminPage />}
          />
        </Route>

        <Route element={<RoutePrivate />}>
          <Route element={<DefaultAccount />}>
            <Route path="meetings" element={<MeetingsPage />} />
            <Route path="account" element={<AccountPage />} />
            <Route path="contacts" element={<ContactsPage />} />
            <Route path={RoutesEnum.CHAT_BOT} element={<ChatBot />} />
          </Route>
        </Route>
        <Route element={<RoutePublic />}>
          <Route path={RoutesEnum.SIGNUP + "/*"} element={<SignupRoutes />} />
          <Route path={RoutesEnum.SIGNIN + "/*"} element={<SigninRoutes />} />
        </Route>
        <Route path="signout" element={<Signout />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
