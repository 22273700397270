import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

interface IUserSliceState {
  events: {
    isOnlyEvents: boolean | null;
    loading: boolean;
  };
  accessToken: string | null;
  user: {
    firstName: string;
    lastName: string;
  } | null;
}

export const fetchUserIsOnlyEvents = createAsyncThunk(
  "user/fetchIsOnlyEvents",
  async (props = undefined, { extra }) => {
    const result = await (extra as any)?.req({
      url: `/persons/me/only-events`,
    });
    return result?.data;
  },
);

const initialState: IUserSliceState = {
  events: {
    isOnlyEvents: null,
    loading: true,
  },
  accessToken: null,
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addToken(
      state: IUserSliceState,
      action: PayloadAction<IUserSliceState["accessToken"]>,
    ) {
      state.accessToken = action.payload;
    },
    addUser(
      state: IUserSliceState,
      action: PayloadAction<IUserSliceState["user"]>,
    ) {
      state.user = action.payload;
    },
    resetState(state: IUserSliceState) {
      state.accessToken = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserIsOnlyEvents.fulfilled, (state, action) => {
        state.events.isOnlyEvents = action.payload;
        state.events.loading = false;
      })
      .addCase(fetchUserIsOnlyEvents.pending, (state) => {
        state.events.loading = true;
      })
      .addCase(fetchUserIsOnlyEvents.rejected, (state) => {
        state.events.loading = false;
      });
  },
});

export const { addToken, addUser, resetState } = userSlice.actions;

export const selectUserEvents = (state: RootState) => state.user.events;
export const selectToken = (state: RootState) => state.user.accessToken;
export const selectUser = (state: RootState) => state.user.user;

export default userSlice.reducer;
