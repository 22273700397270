import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

import { ModalProvider, ReduxProvider } from "./providers";
import Notification from "./content/Notification";
import App from "./App";

import { AuthProvider } from "./context/AuthProvider";
import { queryClient } from "./api/request";
import { fetchCategories } from "./redux/features/categories/fetchCategories";
import { store } from "./redux/store";
import "./index.scss";

store.dispatch(fetchCategories());

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ReduxProvider>
        <AuthProvider>
          <App />
          <Notification />
          <ModalProvider />
        </AuthProvider>
      </ReduxProvider>
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById("root"),
);
