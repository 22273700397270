import clsx from "clsx";

import { useContactsControllerUpdateStatusMutation } from "src/redux/requests/contacts.enhanced";
import { useCloseByOutsideClick } from "src/hooks/useCloseByOutsideClick";
import { showToastSuccess } from "src/common/functions/showToastSuccess";
import { showToastError } from "src/common/functions/showToastError";
import { ContactVm } from "src/redux/requests/contacts.slice";
import { useModal } from "src/hooks/useModal";
import UserGroupIcon from "./user-group.svg";
import UserMinusIcon from "./user-minus.svg";
import StarIcon from "./star.svg";
import styles from "./ContactControls.module.scss";

interface IContactControlsProps {
  data: {
    friendship: ContactVm["friendship"];
    matchCount: ContactVm["matchCount"];
    contactId: string;
  };
}

const messages: Record<ContactVm["friendship"], string> = {
  IDLE: "Пользователь удален из друзей",
  REQUESTED: "Заявка отправлена",
  PENDING: "",
  CONFIRMED: "Заявка подтверждена",
};

export default function ContactControls({ data }: IContactControlsProps) {
  const [updateStatus, { isLoading }] =
    useContactsControllerUpdateStatusMutation();

  const changeStatus = (status: ContactVm["friendship"]) => async () => {
    try {
      await updateStatus({
        applicationDto: {
          contactId: data.contactId,
          status,
        },
      }).unwrap();

      showToastSuccess(messages[status]);
    } catch (e) {
      console.error("UPDATE_CONTACT_STATUS_ERROR: ", e);
      showToastError("Не удалось отправить заявку");
    }
  };

  if (data.matchCount === 1) {
    return (
      <div className={styles.Preview}>
        <img
          className={styles.Preview__Icon}
          loading="lazy"
          height={18}
          width={18}
          src={StarIcon}
          alt="Star icon"
        />
        <p className={styles.Preview__Text}>
          Остался один шажок к дружбе — ещё одна встреча!
        </p>
      </div>
    );
  } else if (data.matchCount >= 2 && data.friendship === "IDLE") {
    return (
      <button
        className={clsx(
          styles.CardControlsButton,
          styles.CardControlsButton_Light,
        )}
        disabled={isLoading}
        onClick={changeStatus("REQUESTED")}
      >
        Отметить другом
      </button>
    );
  } else if (data.matchCount >= 2 && data.friendship === "PENDING") {
    return (
      <button
        className={clsx(
          styles.CardControlsButton,
          styles.CardControlsButton_Dark,
        )}
        disabled={isLoading}
        onClick={changeStatus("CONFIRMED")}
      >
        Подтвердить, что друзья
      </button>
    );
  } else if (data.matchCount >= 2 && data.friendship === "CONFIRMED") {
    return (
      <div className={styles.CardControlsSelectWrapper}>
        <CardControlsSelect onClick={changeStatus("IDLE")} />
      </div>
    );
  } else {
    return <div className={styles.EmptyState} />;
  }
}

interface ICardControlsSelectProps {
  onClick: () => void;
}

function CardControlsSelect({ onClick }: ICardControlsSelectProps) {
  const { isShowing, toggleValue, toggle } = useModal();
  const { ref } = useCloseByOutsideClick(toggleValue);
  return (
    <div className={styles.CardControlsSelect} ref={ref} onClick={toggle}>
      <div className={styles.CardControlsSelect__Input}>
        <img
          className={styles.CardControlsSelect__Icon}
          height={18}
          width={18}
          src={UserGroupIcon}
          loading="lazy"
          alt="Icon"
        />
        <p className={styles.CardControlsSelect__Text}>Вы друзья</p>
        <svg
          className={styles.CardControlsSelect__Chevron}
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.5 4.5L6 8L2.5 4.5"
            stroke="#5378F0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <ul
        className={clsx({
          [styles.CardControlsSelect__List]: true,
          [styles.CardControlsSelect__List_Show]: isShowing,
        })}
      >
        <li className={styles.CardControlsSelect__Item} onClick={onClick}>
          <img
            className={styles.CardControlsSelect__Icon}
            height={18}
            width={18}
            src={UserMinusIcon}
            loading="lazy"
            alt="Icon"
          />
          Больше не друзья
        </li>
      </ul>
    </div>
  );
}
