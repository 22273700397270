import {
  configureStore,
  createListenerMiddleware,
  ThunkAction,
  UnknownAction,
} from "@reduxjs/toolkit";

import apiReducer, { apiSlice } from "./features/api/apiSlice";
import categoriesReducer from "./features/categories/categoriesSlice";
import modalReducer from "./features/modal/modalSlice";
import pollReducer from "./features/poll/pollSlice";
import userReducer from "./features/user/userSlice";
import botReducer from "./features/bot/botSlice";
import { req } from "src/api/request";

export const listenerMiddleware = createListenerMiddleware();

const extraArgument = {
  api: apiSlice,
  req,
};

export const store = configureStore({
  reducer: {
    api: apiReducer,
    categories: categoriesReducer,
    modal: modalReducer,
    poll: pollReducer,
    user: userReducer,
    bot: botReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: { extraArgument },
    })
      .prepend(listenerMiddleware.middleware)
      .concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<R = void> = ThunkAction<
  R,
  RootState,
  typeof extraArgument,
  UnknownAction
>;
