import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { ContactCard } from "./ContactCard";
import { BaseLoader } from "src/components/base/BaseLoader";

import { usePaginatedContacts } from "src/redux/requests/contacts.enhanced";
import { IChipsFilter } from "../Contacts";
import styles from "./Cards.module.scss";

export default function Cards({ filters }: { filters: IChipsFilter }) {
  // const [items, setItems] = useState<any[]>([]);
  const [page, setPage] = useState(1);

  const { data, isFetching, isLoading, pagination } = usePaginatedContacts({
    ...filters,
    limit: 48,
    page,
  });

  // const initRef = useRef(false);

  // useEffect(() => {
  //   setItems([]);
  //   setPage(1);
  //   initRef.current = false;
  // }, [initRef, filters]);

  // useEffect(() => {
  //   if (!data) return;
  //   if (initRef.current) return;
  //   initRef.current = true;
  //   setItems(data.items);
  // }, [initRef, data]);

  // useEffect(() => {
  //   if (!data) return;
  //   if (page > 1) {
  //     for (let item of data.items) {
  //       if (items.find((it) => it.id === item.id)) return;
  //     }
  //     setItems((prev) => [...prev, ...data?.items]);
  //   }
  // }, [data, page, filters]);

  // const handleScroll = () => {
  //   const bottom =
  //     Math.round(document.documentElement.scrollHeight) ===
  //     Math.round(document.documentElement.scrollTop + window.innerHeight);
  //   if (bottom && pagination.hasNextPage) {
  //     setPage((prev) => prev + 1);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [data, isFetching]);

  return (
    <>
      <div
        className={clsx({
          [styles.Cards]: true,
          [styles.Cards_Empty]: data?.items.length === 0,
        })}
      >
        {data?.items.map((item) => (
          <ContactCard data={item} key={item.id} />
        ))}
        {data?.items.length === 0 && !isLoading && !isFetching && (
          <div>Контактов не найдено :(</div>
        )}
      </div>
      {(isFetching || isLoading) && (
        <div
          style={{
            alignSelf: "flex-start",
            justifyItems: "center",
          }}
        >
          {" "}
          <BaseLoader />
        </div>
      )}
    </>
  );
}
