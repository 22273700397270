import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";

import { ChatScoreButton } from "../components/ChatScoreButton";
import { ChatSendButton } from "../components/ChatSendButton";
import { ChatChipButton } from "../components/ChatChipButton";

import { CHAT_CONTROL_BAR_ID } from "../constants";
import { useChatReply } from "./useChatAnswers";
import {
  fetchAvailableAnswers,
  replyQuestion,
  selectAnswers,
  selectTelegramLink,
} from "src/redux/features/bot/botSlice";
import styles from "./ChatControls.module.scss";

interface Props {
  messagesRef: any;
  inputRef: any;
}

export default forwardRef(function ChatControls(
  { messagesRef, inputRef }: Props,
  ref: any,
) {
  const dispatch = useDispatch();
  const telegramLink = useSelector(selectTelegramLink);
  const answers = useSelector(selectAnswers);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchAvailableAnswers() as any);
  }, []);

  const { reply, handleReply, clearReply } = useChatReply();
  const sendReply = async () => {
    // TODO Обработать статусы как положено
    // и уже потом очищать стейт
    setLoading(true);
    try {
      await dispatch(replyQuestion(reply) as any).unwrap();
      clearReply();
      messagesRef.current?.scrollTo({
        behavior: "smooth",
        top: messagesRef.current?.scrollHeight,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  let answerExists = Array.isArray(answers) && answers.length > 0;
  let content;

  if (answerExists) {
    content = (
      <>
        {Array.isArray(answers) && answers.length > 0 && (
          <div className={styles.ChatListAnswers}>
            {answers.map((answer) => {
              const checked = reply.answerIDs.includes(answer.id);

              switch (answer.type) {
                case "SCORE":
                  return (
                    <ChatScoreButton
                      onClick={() => handleReply(answer)}
                      checked={checked}
                      value={answer.text}
                      key={answer.id}
                    />
                  );
                case "LINK":
                  return (
                    <ChatChipButton
                      className={styles.ChatListAnswers__Item}
                      checked={checked}
                      hovered={false}
                      onClick={() => {
                        telegramLink && window.open(telegramLink);
                      }}
                      value={answer.text}
                      icon={
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.66406 4.5013H3.9974C3.26102 4.5013 2.66406 5.09826 2.66406 5.83464V12.5013C2.66406 13.2377 3.26102 13.8346 3.9974 13.8346H10.6641C11.4004 13.8346 11.9974 13.2377 11.9974 12.5013V9.83464M9.33073 3.16797H13.3307M13.3307 3.16797V7.16797M13.3307 3.16797L6.66406 9.83464"
                            stroke="#303030"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      key={answer.id}
                    />
                  );
                default:
                  return (
                    <ChatChipButton
                      className={styles.ChatListAnswers__Item}
                      checked={checked}
                      onClick={() => handleReply(answer)}
                      value={answer.text}
                      key={answer.id}
                    />
                  );
              }
            })}
            <ChatSendButton
              className={clsx({
                [styles.ChatControlsHide]: !answerExists || telegramLink,
                [styles.ChatControls__SendButton_Mobile]: true,
              })}
              disabled={loading || reply.answerIDs.length === 0}
              loading={loading}
              onClick={sendReply}
            />
          </div>
        )}
        <ChatSendButton
          className={clsx({
            [styles.ChatControlsHide]: !answerExists || telegramLink,
            [styles.ChatControls__SendButton_Desktop]: true,
          })}
          disabled={loading || reply.answerIDs.length === 0}
          loading={loading}
          onClick={sendReply}
        />
      </>
    );
  } else {
    content = (
      <p className={styles.ChatControlsPlaceholder}>
        Жди новых сообщений от Тёрки!
      </p>
    );
  }

  return (
    <div
      className={clsx({
        [styles.ChatControls]: true,
        [styles.ChatControls_Empty]: !answerExists,
      })}
      id={CHAT_CONTROL_BAR_ID}
      ref={inputRef}
    >
      {Array.isArray(answers) && answers[0]?.variant === "MULTIPLE" && (
        <div className={styles.ChatControls__Hint}>
          Можно выбрать несколько пунктов
        </div>
      )}
      <div
        className={clsx({
          [styles.ChatControls__Content]: true,
          [styles.ChatControls__Content_Empty]: !answerExists,
        })}
      >
        {content}
      </div>
    </div>
  );
});
