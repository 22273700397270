import moment from "moment";

export function getAgeWithDeclension(birthDate: string): string {
  const age = moment().diff(moment(new Date(birthDate), "YYYY-MM-DD"), "years");
  const pluralRules = new Intl.PluralRules("ru", { type: "ordinal" });
  const forms = {
    one: "год",
    few: "года",
    many: "лет",
    other: "лет",
  };

  const rule = pluralRules.select(age % 100);
  return `${age} ${(forms as any)[rule]}`;
}

export function capitalizeFirstLetter(str: string) {
  return str.replace(/\w/, str[0].toLocaleUpperCase());
}
