import clsx from "clsx";

import styles from "./ChatSendButton.module.scss";
import { CircularProgress } from "@mui/material";

interface ChatSendButtonProps {
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  loading?: boolean;
}

export default function ChatSendButton({
  className,
  disabled,
  loading,
  ...rest
}: ChatSendButtonProps) {
  return (
    <button
      className={clsx(
        styles.ChatSendButton,
        className,
        disabled && styles.ChatSendButton_Disabled,
      )}
      disabled={disabled}
      {...rest}
    >
      {loading ? (
        <CircularProgress className={styles.ChatSendButton__Loader} />
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23 12L5 3L7 12L5 21L23 12Z" fill="white" />
          <path
            d="M7 12L5 21L23 12L5 3L7 12ZM7 12H15"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </button>
  );
}
