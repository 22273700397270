import { AppThunk } from "../../store";
import { ICategoriesSliceState, addCategory } from "./categoriesSlice";

const urls = [
  `${process.env.REACT_APP_BACKEND_URL}/api/categories/hobbies`,
  `${process.env.REACT_APP_BACKEND_URL}/api/categories/interests`,
  `${process.env.REACT_APP_BACKEND_URL}/api/categories/needs`,
  `${process.env.REACT_APP_BACKEND_URL}/api/categories/connections`,
];

export const fetchCategories = (): AppThunk => async (dispatch, _) => {
  const requests = urls.map((url) => fetch(url));
  const response = await Promise.allSettled(requests);
  const result = await Promise.all(
    response.map((response) => {
      if (response.status === "fulfilled" && response.value.ok) {
        return response.value.json();
      }

      return [];
    }),
  );

  result.forEach((data, i) => {
    dispatch(
      addCategory({
        key: urls[i].split("/").at(-1) as keyof ICategoriesSliceState,
        data,
      }),
    );
  });
};
