import clsx from "clsx";

import styles from "./ChatChipButton.module.scss";

interface ChatChipButtonProps {
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  onClick: () => void;
  hovered?: boolean;
  value?: string;
  icon?: React.ReactNode;
}

export default function ChatChipButton({
  className,
  checked,
  hovered = true,
  icon,
  value,
  ...rest
}: ChatChipButtonProps) {
  return (
    <button
      className={clsx(styles.ChatChipButton, className, {
        [styles.ChatChipButton_Checked]: checked,
        [styles.ChatChipButton_Hovered]: hovered,
      })}
      {...rest}
    >
      {value}
      {icon && icon}
    </button>
  );
}
