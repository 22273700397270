import React from "react";
import clsx from "clsx";

import { BaseIcon } from "src/components/base/BaseIcon";

import { useCloseByOutsideClick } from "src/hooks/useCloseByOutsideClick";
import { OptionType } from "src/common/types/option-type";
import { useModal } from "src/hooks/useModal";
import styles from "./SelectContained.module.scss";

type CommonPropsType = {
  className?: string;
  placeholder?: string;
  options: OptionType[];
  disabled?: boolean;
  error?: boolean;
};

type PropsTypeMultiple = CommonPropsType & {
  onChange: (value: OptionType[]) => void;
  value?: OptionType[];
  multiple: true;
  variant?: "tags" | null;
};

type PropsTypeSingle = CommonPropsType & {
  onChange: (value: OptionType) => void;
  value?: OptionType;
  multiple: false;
  variant?: null;
};

type PropsType = PropsTypeMultiple | PropsTypeSingle;

export default function SelectContained({
  className = "",
  multiple,
  placeholder = "",
  onChange,
  options,
  value,
  variant = null,
  error,
  disabled = false,
}: PropsType) {
  const { toggle, toggleValue, isShowing } = useModal();
  const { ref } = useCloseByOutsideClick(toggleValue);

  // To open drop down menu by click on select
  const handleOpenSelect = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    toggle();
  };

  // To handle value
  // if multiple = true we are transforming of the array to string
  // if multiple = false we take name of option
  const handledValue = React.useMemo(() => {
    if (!value) return;
    if (multiple) {
      if (value?.length === 0) return;
      return value.map(({ name, ...rest }, i) =>
        variant === "tags"
          ? { name, ...rest }
          : i === value.length - 1
          ? name
          : name + ", ",
      );
    } else {
      return value?.name;
    }
  }, [variant, value]);

  // To check value, was chose or not
  const isChecked = React.useMemo(
    () =>
      (value: OptionType[] = [], itemId: string): boolean =>
        !!value.find(({ id }) => id === itemId),
    [value],
  );

  // To handle selected item
  const handleSelection = React.useMemo(
    () =>
      (item: OptionType) =>
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (disabled) return;
        if (multiple) {
          if (value) {
            const copyValues = [...value];
            if (isChecked(copyValues, item.id)) {
              const newValue = copyValues.filter(({ id }) => item.id !== id);
              onChange(newValue);
            } else {
              const newValue = [...copyValues, item];
              onChange(newValue);
            }
          } else {
            onChange([item]);
          }
        } else {
          onChange(item);
          toggleValue(false);
        }
      },
    [value],
  );

  return (
    <div
      className={clsx({
        [styles.SelectContained]: true,
        [styles.SelectContained_required]: error,
        [className]: !!className,
      })}
      style={disabled ? { opacity: 0.5 } : undefined}
      onClick={handleOpenSelect}
      ref={ref}
    >
      <div className={styles.SelectContained__input}>
        <div
          className={clsx({
            [styles.SelectContained__content]: true,
            [styles.SelectContained__content_tags]: variant === "tags",
          })}
        >
          {handledValue ? (
            Array.isArray(handledValue) && multiple && variant === "tags" ? (
              handledValue.map((item: any) => (
                <div className={styles.SelectContained__tag} key={item.id}>
                  {item?.sign && (
                    <img
                      className={styles.SelectContained__tagImg}
                      height={18}
                      width={18}
                      src={process.env.REACT_APP_BACKEND_URL + item.sign}
                      loading="lazy"
                      alt="Icon"
                    />
                  )}
                  {item.name}
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.82067 7.00108L11.4957 3.33192C11.6055 3.22207 11.6672 3.07309 11.6672 2.91775C11.6672 2.76241 11.6055 2.61343 11.4957 2.50358C11.3858 2.39374 11.2369 2.33203 11.0815 2.33203C10.9262 2.33203 10.7772 2.39374 10.6673 2.50358L6.99817 6.17858L3.32901 2.50358C3.21916 2.39374 3.07018 2.33203 2.91484 2.33203C2.7595 2.33203 2.61052 2.39374 2.50067 2.50358C2.39083 2.61343 2.32912 2.76241 2.32912 2.91775C2.32912 3.07309 2.39083 3.22207 2.50067 3.33192L6.17567 7.00108L2.50067 10.6703C2.446 10.7245 2.4026 10.789 2.37299 10.8601C2.34337 10.9312 2.32812 11.0074 2.32812 11.0844C2.32812 11.1614 2.34337 11.2377 2.37299 11.3088C2.4026 11.3798 2.446 11.4444 2.50067 11.4986C2.5549 11.5533 2.61942 11.5967 2.6905 11.6263C2.76159 11.6559 2.83783 11.6711 2.91484 11.6711C2.99185 11.6711 3.06809 11.6559 3.13918 11.6263C3.21026 11.5967 3.27478 11.5533 3.32901 11.4986L6.99817 7.82358L10.6673 11.4986C10.7216 11.5533 10.7861 11.5967 10.8572 11.6263C10.9283 11.6559 11.0045 11.6711 11.0815 11.6711C11.1585 11.6711 11.2348 11.6559 11.3058 11.6263C11.3769 11.5967 11.4414 11.5533 11.4957 11.4986C11.5503 11.4444 11.5937 11.3798 11.6234 11.3088C11.653 11.2377 11.6682 11.1614 11.6682 11.0844C11.6682 11.0074 11.653 10.9312 11.6234 10.8601C11.5937 10.789 11.5503 10.7245 11.4957 10.6703L7.82067 7.00108Z"
                      fill="#303030"
                    />
                  </svg>
                </div>
              ))
            ) : (
              handledValue
            )
          ) : (
            <span className={styles.BaseSelect__placeholder}>
              {placeholder}
            </span>
          )}
        </div>
        <BaseIcon
          className={clsx({
            [styles.SelectContained__icon]: true,
            [styles.SelectContained__icon_active]: isShowing,
          })}
          viewBox="0 0 14 9"
          height="9"
          width="14"
          icon="ARROW_DOWN_OUTLINED"
          fill="none"
        />
      </div>
      <div
        className={clsx({
          [styles.SelectContained__options]: true,
          [styles.SelectContained__options_hide]: isShowing,
        })}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {options.map((option: any) => {
          // TODO ПЛОХОЙ ВАРИАНТ, НАДО СДЕЛАТЬ объект и из него брать будет ~ O(1)
          const checked =
            Array.isArray(value) && value.find((item) => item.id === option.id);
          return (
            <div
              className={clsx(
                styles.SelectContained__option,
                checked && styles.SelectContained__option_checked,
              )}
              onClick={handleSelection(option)}
              key={option.id}
            >
              <div className={styles.SelectContained__content}>
                {option?.sign && (
                  <img
                    className={styles.SelectContained__tagImg}
                    height={18}
                    width={18}
                    src={process.env.REACT_APP_BACKEND_URL + option.sign}
                    loading="lazy"
                    alt="Icon"
                  />
                )}{" "}
                {option.name}
              </div>
              <BaseIcon
                className={clsx(
                  styles.SelectContained__option_icon,
                  checked && styles.SelectContained__option_icon_checked,
                )}
                viewBox="0 0 18 13"
                height="13"
                width="18"
                fill="none"
                icon="CHECK"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
