import clsx from "clsx";

import { CheckedIcon } from "src/components/base/BaseIcons";
import styles from "./Checkbox.module.scss";

export interface IProps {
  className?: string;
  onChange: (value: boolean) => void;
  checked: boolean;
  error?: string;
}

export default function Checkbox({
  className,
  onChange,
  error,
  ...rest
}: IProps) {
  return (
    <div
      className={clsx(
        styles.Checkbox,
        error ? styles.Checkbox_Error : undefined,
        className,
      )}
      role="checkbox"
    >
      {rest.checked && <CheckedIcon className={styles.Checkbox__Icon} />}
      <input
        className={styles.Checkbox__Input}
        onChange={(e) => onChange(e.target.checked)}
        type="checkbox"
        {...rest}
      />
    </div>
  );
}
