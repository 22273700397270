import * as yup from "yup";

export const PASSWORD_REG_EXP = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/gi;
export const NAME_REG_EXP = /^(?=.*[а-яА-ЯёЁ])(?=.{2,})/gi;
export const CODE_REG_EXP = /\d\d\d\-\d\d\d/gi;

export const SignupStep1Validation = yup.object().shape({
  email: yup
    .string()
    .email("Некорректный email")
    .required("Это обязательное поле"),
  wasAcceptedPolicy: yup.boolean().equals([true], "Это обязательное поле"),
});

export const SignupStep2Validation = yup.object().shape({
  n1: yup.number().required("Это обязательное поле"),
  n2: yup.number().required("Это обязательное поле"),
  n3: yup.number().required("Это обязательное поле"),
  n4: yup.number().required("Это обязательное поле"),
});

export const SignupStep4Validation = yup.object().shape({
  firstName: yup
    .string()
    .required("Это обязательное поле")
    .matches(NAME_REG_EXP, "Некорректные данные")
    .max(30, "Максимальное количество символов - 30"),
  lastName: yup
    .string()
    .required("Это обязательное поле")
    .matches(NAME_REG_EXP, "Некорректные данные")
    .max(30, "Максимальное количество символов - 30"),
  liveInKaliningrad: yup.boolean().required("Это обязательное поле"),
  readyToChatOnline: yup.boolean().required("Это обязательное поле"),
});

export const EnterPasswordValidation = yup.object().shape({
  password: yup
    .string()
    .required("Это обязательное поле")
    .matches(PASSWORD_REG_EXP, "Не менее 8 символов: латиница и цифры"),
  confirmPassword: yup
    .string()
    .required("Это обязательное поле")
    .oneOf([yup.ref("password"), null], "Пароли должны совпадать"),
});

export const FeedbackValidation = yup.object().shape({
  score: yup.number().required("Обязательное поле"),
  qualities: yup.array().required("Обязательное поле"),
  comment: yup.string().required("Обязательное поле"),
});

export const SigninValidation = yup.object().shape({
  email: yup
    .string()
    .email("Некорректный email")
    .required("Это обязательное поле"),
  password: yup.string().required("Это обязательное поле"),
});

export const EmailValidation = yup.object().shape({
  email: yup
    .string()
    .email("Некорректный email")
    .required("Это обязательное поле"),
});

export const CreatorCredsScheme = yup.object().shape({
  creatorTg: yup
    .string()
    .required("Это обязательное поле")
    .matches(
      /^(https?:\/\/)?(t\.me\/|@)([a-zA-Z0-9_.-]+){5,32}$/,
      "Введите username с «@» или ссылку на профиль",
    ),
  creatorMobile: yup
    .string()
    .required("Это обязательное поле")
    .matches(/^(?:7|8)\d{10}$/, "Неверный номер телефона"),
});

export const EventScheme = yup.object().shape(
  {
    organizer: yup
      .string()
      .min(1, "Это обязательное поле")
      .max(80, "Организатор не более 80 символов")
      .required("Это обязательное поле"),
    title: yup
      .string()
      .min(1, "Это обязательное поле")
      .max(80, "Не более 80 символов")
      .required("Это обязательное поле"),
    text: yup
      .string()
      .max(2000, "Не более 2000 символов")
      .required("Это обязательное поле"),
    address: yup.string().required("Это обязательное поле"),
    meetingAt: yup.string().required("Это обязательное поле"),
    eventType: yup
      .array()
      .max(3, "Не более 3 тегов")
      .required("Это обязательное поле"),
    eventLink: yup.string().url("Некорректный адрес"),
    registrationLink: yup.string().url("Некорректный адрес"),
    isFree: yup.boolean().required("Это обязательное поле"),
    eventsLink: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        link: yup.string().url("Некорректный адрес"),
      }),
    ),
  },
  [
    ["telegramLink", "telegramLink"],
    ["ticketUrl", "ticketUrl"],
    ["vendorCode", "vendorCode"],
  ],
);
