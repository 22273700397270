import { Navigate, Outlet } from "react-router-dom";

import { FullScreenLoader } from "src/content/FullScreenLoader";
import { useMe } from "src/api/hooks/personsHooks";
import { RoutesEnum } from "src/common/enums";

export const RoutePrivate = () => {
  const { data, isLoading } = useMe();
  const utmSource = new URLSearchParams(window.location.search).get(
    "utm_source",
  );

  if (isLoading) {
    return <FullScreenLoader />;
  } else if (!isLoading && !data) {
    return (
      <Navigate
        to={
          utmSource
            ? RoutesEnum.SIGNIN + "?utm_source=" + utmSource
            : RoutesEnum.SIGNIN
        }
        replace
        state={{
          path: location.pathname,
        }}
      />
    );
  } else {
    return <Outlet />;
  }
};
