import { memo, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { selectCategory } from "src/redux/features/categories/categoriesSlice";
import { showToastError } from "src/common/functions/showToastError";
import {
  incrementStep,
  selectForm,
  addForm,
} from "src/redux/features/poll/pollSlice";
import styles from "./CategoryForm.module.scss";

interface ICategoryForm {
  className?: string;
  formId: string;
  category: "hobbies" | "interests" | "needs" | "connections";
  nextStep: number;
  validationSchema: z.ZodArray<z.ZodString, "atleastone">;
}

export default function CategoryForm({
  formId,
  category,
  nextStep,
  validationSchema,
}: ICategoryForm) {
  const storedCategories = useSelector(selectForm(category)) as string[];
  const dispatch = useDispatch();

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await validationSchema.parseAsync(storedCategories);
    } catch (e) {
      console.error("ValidateSchema: ", e);
      showToastError("Выберите минимум 1 вариант");
      return;
    }

    dispatch(incrementStep());
  };

  const onChange = useCallback((data: string[]) => {
    dispatch(addForm({ key: category, data }));
  }, []);

  const data = useSelector(selectCategory(category));

  return (
    <form id={formId} onSubmit={onSubmit}>
      <MemoizedCategoryList
        onChange={onChange}
        value={storedCategories}
        list={data}
      />
    </form>
  );
}

interface ICategoryListProps {
  onChange: (newValue: string[]) => void;
  value: string[];
  list?: { id: string; name: string; sign: string }[];
}

function CategoryList({ onChange, value, list }: ICategoryListProps) {
  console.count("CategoryList");

  const onSelect = (newCategoryId: string) => () => {
    let newValue = value.includes(newCategoryId)
      ? value.filter((chosenCategoryId) => chosenCategoryId !== newCategoryId)
      : value.concat(newCategoryId);

    onChange(newValue);
  };

  const colorsRef = useRef([
    "#FFE4C1",
    "#D7EDFF",
    "#CFFFCA",
    "#FFE2EB",
    "#DAD4FF",
  ]);

  const chosenCategories = useMemo(() => {
    let result: Record<string, { color: string; id: string }> = {};
    let index = 0;

    for (let i = 0; i < value.length; i++) {
      if (index % 5 === 0) index = 0;
      let id = value[i];
      result[id] = {
        color: colorsRef.current[index],
        id,
      };
      index++;
    }

    return result;
  }, [value]);

  return (
    <ul className={styles.CategoryList}>
      {list?.map(({ id, name, sign }) => (
        <li
          className={styles.CategoryList__Item}
          onClick={onSelect(id)}
          data-key={chosenCategories[id] ? "active" : undefined}
          style={
            chosenCategories[id]
              ? { background: chosenCategories[id].color }
              : undefined
          }
          key={id}
        >
          {sign && (
            <img
              className={styles.CategoryList__Icon}
              src={process.env.REACT_APP_BACKEND_URL + sign}
              height={14}
              loading="lazy"
              width={14}
            />
          )}
          {name}
        </li>
      ))}
    </ul>
  );
}

const MemoizedCategoryList = memo(CategoryList);
