import { memo } from "react";

import { ContactHeader } from "./ContactHeader";
import { ContactBio } from "./ContactBio";
import { ContactControls } from "./ContactControls";
import { ContactSocials } from "./ContactSocials";
import { ContactComment } from "./ContactComment";

import { ContactVm } from "src/redux/requests/contacts.slice";
import styles from "./ContactCard.module.scss";

interface IContactCardProps {
  data: ContactVm;
}

function ContactCard({ data }: IContactCardProps) {
  return (
    <div className={styles.Card}>
      <ContactHeader
        data={{
          latestMatch: data.latestMatch,
          lastScores: data.lastScores,
          avatar: data.avatar,
          gender: data.gender,
        }}
      />
      <ContactBio
        data={{
          firstName: data.firstName,
          lastName: data.lastName,
          birthday: data.birthday,
          city: data.city,
          isAbroad: data.isAbroad,
        }}
      />
      <ContactControls
        data={{
          friendship: data.friendship,
          matchCount: data.matchCount,
          contactId: data.id,
        }}
      />
      <ContactSocials data={{ personSocials: data.personSocials }} />
      <ContactComment data={{ comment: data.comment, contactId: data.id }} />
    </div>
  );
}

const MCC = memo(ContactCard);

export default MCC;
