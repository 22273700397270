import { apiSlice as api } from "../features/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    personsControllerCreateAvatar: build.mutation<
      PersonsControllerCreateAvatarApiResponse,
      PersonsControllerCreateAvatarApiArg
    >({
      query: (queryArg) => ({
        url: `/api/persons/me/avatar`,
        method: "POST",
        body: queryArg.createAvatarDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as persons };
export type PersonsControllerCreateAvatarApiResponse = unknown;
export type PersonsControllerCreateAvatarApiArg = {
  createAvatarDto: CreateAvatarDto;
};
export type CreateAvatarDto = {
  avatar?: Blob;
};
export const { usePersonsControllerCreateAvatarMutation } = injectedRtkApi;
