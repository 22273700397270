import { Navigation } from "../../components/Navigation";

import styles from "./PollLayout.module.scss";

interface IPollLayoutProps {
  formId: string;
  head: React.ReactNode;
  form: React.ReactNode;
}

export default function PollLayout({ head, form, formId }: IPollLayoutProps) {
  return (
    <section className={styles.PollLayout}>
      <div className={styles.PollLayout__Form}>
        {head}
        {form}
      </div>
      <Navigation className={styles.PollLayout__Navigation} formId={formId} />
    </section>
  );
}
