import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import clsx from "clsx";

import { addStep, selectStep } from "src/redux/features/poll/pollSlice";
import styles from "./Navigation.module.scss";

interface IProps {
  className?: string;
  formId: string;
}

export default function Navigation({ className, formId }: IProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const step = useSelector(selectStep);

  useEffect(() => {
    // let stepValue = +params["*"]!.split("/").at(-1)!;
    // if (step.current !== stepValue) {
    //   dispatch(addStep(stepValue));
    // }
  }, []);

  const onPrev = () => {
    if (step.current > 1) {
      dispatch(addStep(step.current - 1));
      return;
    }

    navigate("/");
  };

  console.count("Navigation");

  return (
    <div className={clsx(styles.Navigation, className)}>
      <button
        className={clsx(styles.NavigationButton, styles.Navigation__Button)}
        onClick={onPrev}
      >
        <svg
          className={styles.NavigationButton__Icon}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.33333 15.8346L2.5 10.0013M2.5 10.0013L8.33333 4.16797M2.5 10.0013L17.5 10.0013"
            stroke="#333236"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <div className={styles.Navigation__Value}>
        Шаг {step.current}/{step.max}
      </div>
      <button
        className={clsx(styles.NavigationButton, styles.Navigation__Button)}
        form={formId}
        type="submit"
      >
        <svg
          className={styles.NavigationButton__Icon}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6667 4.16536L17.5 9.9987M17.5 9.9987L11.6667 15.832M17.5 9.9987L2.5 9.9987"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}
