import { z } from "zod";

import { socialsSchema } from "src/common/validation/socials-schema";

// Схема для активности
const activitySchema = z.object({
  proffessional: z.number().min(0).max(10),
  romantic: z.number().min(0).max(10),
  chat: z.number().min(0).max(10),
});

// Схема для пола собеседника
const interlocutorGenderSchema = z
  .object({
    male: z.boolean(),
    female: z.boolean(),
  })
  .superRefine((data, ctx) => {
    // Проверяем, что хотя бы одно из полей `male` или `female` равно true
    if (!data.male && !data.female) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Необходимо выбрать хотя бы один пол: male или female",
        path: ["male"],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Необходимо выбрать хотя бы один пол: male или female",
        path: ["female"],
      });
    }
  });
// Схема для возраста собеседника
const interlocutorAgeSchema = z
  .object({
    from: z.number().min(0).max(120),
    till: z.number().min(0).max(120),
  })
  .refine((data) => data.from <= data.till, {
    message: "'from' должно быть меньше или равно 'till'",
    path: ["from"],
  });

// Основная схема

export const RegistrationStep6Schema = z.object({
  aboutMe: z.string().min(1, "Поле aboutMe обязательно").max(500),
  gender: z.string().min(1, "Поле gender обязательно"),
  birthday: z.date(),
  city: z.string().nullable().optional(),
  avatar: z.instanceof(File).nullable(),
  isAbroad: z.boolean(),
  activity: activitySchema,
  interlocutorGender: interlocutorGenderSchema,
  interlocutorAge: interlocutorAgeSchema,
  socials: socialsSchema,
  isNotifyByEvents: z.boolean(),
});
