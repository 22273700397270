import React, { ReactNode, useState } from "react";
import clsx from "clsx";

import style from "./BaseDropDown.module.scss";
import { useCloseByOutsideClick } from "../../../hooks/useCloseByOutsideClick";
import { BaseIcon } from "../BaseIcon";
import { Link } from "react-router-dom";

type item = {
  label: string;
  onClick?: () => void;
  href?: string;
};

type PropsType = {
  children: ReactNode;
  list: item[];
  isWhiteHeader?: boolean;
};

const BaseDropDown = ({ children, list, isWhiteHeader }: PropsType) => {
  const [isActive, onToggle] = useState(false);
  const { ref } = useCloseByOutsideClick(onToggle);
  const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    onToggle(!isActive);
  };

  return (
    <div className={style.BaseDropDown}>
      <div
        className={style.BaseDropDown__children}
        onClick={onClickHandler}
        ref={ref}
      >
        {children}

        <svg
          className={clsx(style.BaseDropDown__Arrow, {
            [style.BaseDropDown__Arrow_Rotate]: isActive,
          })}
          width="14"
          height="9"
          viewBox="0 0 14 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 7.5L7 1.5L13 7.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <nav
        className={clsx({
          [style.BaseDropDown__menu]: true,
          [style.BaseDropDown__active]: isActive,
        })}
      >
        <ul className={style.BaseDropDown__menu_list}>
          {list.map(({ ...item }, key) =>
            item.href ? (
              <Link
                className={style.BaseDropDown__menu_item}
                to={item.href}
                key={key}
              >
                {item.label}
              </Link>
            ) : (
              <li
                className={style.BaseDropDown__menu_item}
                onClick={item.onClick}
                key={key}
              >
                {item.label}
              </li>
            ),
          )}
        </ul>
      </nav>
    </div>
  );
};

export default BaseDropDown;
