import { useLocation } from "react-use";
import { Outlet } from "react-router-dom";
import clsx from "clsx";

import { AccountHeader } from "src/components/partials";
import { BaseContainer } from "src/components/base";
import { NavBar } from "src/content/NavBar";

import { RoutesEnum } from "src/common/enums";
import { useMe } from "src/api/hooks/personsHooks";
import styles from "./DefaultAccount.module.scss";

const DefaultAccount = ({ isWhiteHeader }: { isWhiteHeader?: boolean }) => {
  const { data: me } = useMe();
  const path = useLocation();
  const chat = path.pathname === RoutesEnum.CHAT_BOT;
  return (
    <div className={styles.DefaultAccount}>
      <div className={styles.DefaultAccount__header}>
        {me ? (
          <AccountHeader isWhiteHeader={isWhiteHeader} />
        ) : (
          <NavBar variant="account" isWhiteHeader={isWhiteHeader} />
        )}
      </div>
      <div
        className={clsx({
          [styles.DefaultAccount__body]: true,
          [styles.DefaultAccount__body_lines]: chat,
        })}
      >
        <BaseContainer>
          <Outlet />
        </BaseContainer>
      </div>
    </div>
  );
};

export default DefaultAccount;
