import { apiSlice as api } from "../features/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerLogin: build.query<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: () => ({ url: `/api/auth/login` }),
    }),
    authControllerSignup: build.mutation<
      AuthControllerSignupApiResponse,
      AuthControllerSignupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/signup`,
        method: "POST",
        body: queryArg.signupDto,
      }),
    }),
    authControllerSignupForStore: build.mutation<
      AuthControllerSignupForStoreApiResponse,
      AuthControllerSignupForStoreApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/signup-store`,
        method: "POST",
        body: queryArg.signupDto,
      }),
    }),
    authControllerSignin: build.mutation<
      AuthControllerSigninApiResponse,
      AuthControllerSigninApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/signin`,
        method: "POST",
        body: queryArg.signinDto,
      }),
    }),
    authControllerSignout: build.mutation<
      AuthControllerSignoutApiResponse,
      AuthControllerSignoutApiArg
    >({
      query: () => ({ url: `/api/auth/signout`, method: "POST" }),
    }),
    authControllerRefreshTokens: build.mutation<
      AuthControllerRefreshTokensApiResponse,
      AuthControllerRefreshTokensApiArg
    >({
      query: () => ({ url: `/api/auth/refresh`, method: "POST" }),
    }),
    authControllerSendConfirmationCode: build.mutation<
      AuthControllerSendConfirmationCodeApiResponse,
      AuthControllerSendConfirmationCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/actions/send-code`,
        method: "POST",
        body: queryArg.sendCodeDto,
      }),
    }),
    authControllerConfirmCode: build.mutation<
      AuthControllerConfirmCodeApiResponse,
      AuthControllerConfirmCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/actions/confirm-code`,
        method: "POST",
        body: queryArg.confirmCodeDto,
      }),
    }),
    authControllerValidateEmail: build.mutation<
      AuthControllerValidateEmailApiResponse,
      AuthControllerValidateEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/validate/email`,
        method: "POST",
        body: queryArg.validateEmailDto,
      }),
    }),
    authControllerValidateTelegram: build.mutation<
      AuthControllerValidateTelegramApiResponse,
      AuthControllerValidateTelegramApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/validate/telegram`,
        method: "POST",
        body: queryArg.validateTelegramDto,
      }),
    }),
    authControllerValidateAuth: build.mutation<
      AuthControllerValidateAuthApiResponse,
      AuthControllerValidateAuthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/validate/auth`,
        method: "POST",
        body: queryArg.validateAuthDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as auth };
export type AuthControllerLoginApiResponse = unknown;
export type AuthControllerLoginApiArg = void;
export type AuthControllerSignupApiResponse =
  /** status 201 Успешный запрос */ TokensVm;
export type AuthControllerSignupApiArg = {
  signupDto: SignupDto;
};
export type AuthControllerSignupForStoreApiResponse = unknown;
export type AuthControllerSignupForStoreApiArg = {
  signupDto: SignupDto;
};
export type AuthControllerSigninApiResponse =
  /** status 201 Успешный запрос */ TokensVm;
export type AuthControllerSigninApiArg = {
  signinDto: SigninDto;
};
export type AuthControllerSignoutApiResponse = unknown;
export type AuthControllerSignoutApiArg = void;
export type AuthControllerRefreshTokensApiResponse =
  /** status 201 Успешный запрос */ TokensVm;
export type AuthControllerRefreshTokensApiArg = void;
export type AuthControllerSendConfirmationCodeApiResponse = unknown;
export type AuthControllerSendConfirmationCodeApiArg = {
  sendCodeDto: SendCodeDto;
};
export type AuthControllerConfirmCodeApiResponse = unknown;
export type AuthControllerConfirmCodeApiArg = {
  confirmCodeDto: ConfirmCodeDto;
};
export type AuthControllerValidateEmailApiResponse =
  /** status 201 Успешный запрос */ IsExistVm;
export type AuthControllerValidateEmailApiArg = {
  validateEmailDto: ValidateEmailDto;
};
export type AuthControllerValidateTelegramApiResponse =
  /** status 201 Успешный запрос */ IsExistVm;
export type AuthControllerValidateTelegramApiArg = {
  validateTelegramDto: ValidateTelegramDto;
};
export type AuthControllerValidateAuthApiResponse =
  /** status 201 Успешный запрос */ IsValidAuth;
export type AuthControllerValidateAuthApiArg = {
  validateAuthDto: ValidateAuthDto;
};
export type TokensVm = {
  accessToken: string;
  refreshToken: string;
};
export type PollCommunicationDto = {
  proffessional: number;
  romantic: number;
  chat: number;
};
export type PollGenderDto = {
  male: boolean;
  female: boolean;
};
export type PollAgeDto = {
  from: number;
  till: number;
};
export type PollDto = {
  communication: PollCommunicationDto;
  gender: PollGenderDto;
  age: PollAgeDto;
};
export type SocialsDto = {
  telegram: string;
  vk: string;
  whatsapp: string;
  facebook: string;
  instagram: string;
};
export type SignupDto = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  hobbies: string[];
  interests: string[];
  needs: string[];
  connections: string[];
  aboutMe: string;
  gender:
    | "\u041C\u0443\u0436\u0441\u043A\u043E\u0439"
    | "\u0416\u0435\u043D\u0441\u043A\u0438\u0439";
  birthday: string;
  city?: string;
  aboutInterlocutor: string;
  poll: PollDto;
  avatar?: Blob;
  socials: SocialsDto;
  isNotifyByEvents: boolean;
  isAbroad?: boolean;
};
export type SigninDto = {
  email: string;
  password: string;
};
export type SendCodeDto = {
  email: string;
};
export type ConfirmCodeDto = {
  code: string;
  email: string;
};
export type IsExistVm = {
  isExists: boolean;
};
export type ValidateEmailDto = {
  email: string;
};
export type ValidateTelegramDto = {
  telegram: string;
};
export type IsValidAuth = {
  isValid: boolean;
};
export type ValidateAuthDto = {
  accessToken: string;
};
export const {
  useAuthControllerLoginQuery,
  useAuthControllerSignupMutation,
  useAuthControllerSignupForStoreMutation,
  useAuthControllerSigninMutation,
  useAuthControllerSignoutMutation,
  useAuthControllerRefreshTokensMutation,
  useAuthControllerSendConfirmationCodeMutation,
  useAuthControllerConfirmCodeMutation,
  useAuthControllerValidateEmailMutation,
  useAuthControllerValidateTelegramMutation,
  useAuthControllerValidateAuthMutation,
} = injectedRtkApi;
