import { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { TextArea } from "src/pages/signup-routes/components/Input/textarea";

import { useContactsControllerLeaveCommentMutation } from "src/redux/requests/contacts.enhanced";
import styles from "./ContactComment.module.scss";

interface IContactCommentProps {
  className?: string;
  data: {
    contactId: string; // id пользователя, что является контактом
    comment: string;
  };
}

const PLACEHOLDER = "Добавить комментарий";

export default function ContactComment({
  className,
  data,
}: IContactCommentProps) {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState(data?.comment ?? "");

  const onChange = useCallback((comment) => {
    setText(comment);
  }, []);

  const [leaveComment] = useContactsControllerLeaveCommentMutation();
  const prevStateRef = useRef(text);

  const onBlur = useCallback(
    (comment: string, contactId: string) => async () => {
      if (prevStateRef.current === comment) {
        setEdit((prev) => !prev);
        return;
      }

      try {
        await leaveComment({
          commentDto: {
            comment: comment.trim(),
            contactId,
          },
        }).unwrap();

        setText((prev) => prev.trim());
        setEdit((prev) => !prev);
        prevStateRef.current = comment;
      } catch (e) {
        console.error("LEAVE_COMMENT_ERROR: ", e);
      }
    },
    [],
  );

  const onClick = () => {
    setEdit((prev) => !prev);
  };

  useEffect(() => {
    if (!edit) return;

    const textarea = document.querySelector("#comment") as HTMLTextAreaElement;
    const length = textarea.value.length;

    textarea.setSelectionRange(length, length);
    textarea.focus();
  }, [edit]);

  return edit ? (
    <TextArea
      placeholder={PLACEHOLDER}
      className={clsx(styles.ContactComment, className)}
      maxLength={180}
      onChange={onChange}
      onBlur={onBlur(text, data.contactId)}
      value={text}
      error={false}
      disableBreak={true} // блокирует клавишу Enter
      id="comment"
    />
  ) : (
    <div
      className={clsx(
        styles.ContactComment,
        styles.ContactCommentPreview,
        className,
      )}
      onClick={onClick}
    >
      {text || PLACEHOLDER}
    </div>
  );
}
