import { AccountTabs } from "../../content";
import {
  AccountCredentials,
  AccountMeets,
  AccountOrders,
} from "./AccountBlock";

import imageGopnik from "./gopnik-vid-s-zadi.webp";
import styles from "./styles.module.scss";

export default function Account() {
  return (
    <section className={styles.Account}>
      <div className={styles.Account__blocks}>
        <AccountCredentials />
        <AccountTabs>
          <AccountMeets />
          <AccountOrders />
        </AccountTabs>
      </div>
      <img
        className={styles.Account__image}
        loading="lazy"
        height={483}
        width={394}
        alt="Изображение человека"
        src={imageGopnik}
      />
    </section>
  );
}
