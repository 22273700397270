import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import clsx from "clsx";
import InputMask from "react-input-mask";
import momentTz from "moment-timezone";
import moment from "moment/moment";

import { BaseIcon } from "../BaseIcon";
import { useCloseByOutsideClick } from "../../../hooks/useCloseByOutsideClick";

import {
  NextIcon,
  NextIcon2,
  PrevIcon,
  PrevIcon2,
} from "./InputDateWithCalendar.icons";
import styles from "./InputDateWithCalendar.module.scss";

type PropsType = {
  className?: string;
  error?: boolean;
  defaultValue?: string | Date;
  // value: Date | undefined;
  onChange: (value: any) => void;
};

const InputDateWithCalendar = ({
  className = "",
  error,
  onChange,
  defaultValue,
  ...props
}: PropsType) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const { ref } = useCloseByOutsideClick(setShowCalendar);

  // min and current date
  const minDate = new Date(1920, 0, 1, 0, 0, 0);
  const eighteen = 18 * 365.3 * 24 * 60 * 60 * 1000;
  const currentDate = momentTz.tz("Europe/Moscow").toDate();
  const eighteenYearAgo = moment(
    currentDate.setTime(currentDate.getTime() - eighteen),
  ).toDate();

  const [date, setDate] = useState(eighteenYearAgo); //стейт календаря
  const [string, setString] = useState(
    defaultValue instanceof Date
      ? defaultValue.toLocaleDateString("ru-RU")
      : defaultValue && defaultValue.length > 0
      ? new Date(defaultValue).toLocaleDateString("ru-RU")
      : "",
  ); //стейт инпута

  function onChangeCalendar(nextValue: any) {
    //смена через календарь
    setDate(nextValue);
    const newInputValue = nextValue.toLocaleDateString("ru-RU");

    setString(newInputValue); //в инпут
    onChange(nextValue);
  }

  const onChangeInput = (nextValue: string) => {
    // смена через инпут
    setString(nextValue);

    // Попытка парсинга введенной даты
    const parsedDate = moment(nextValue, "DD.MM.YYYY");

    if (
      parsedDate.isValid() &&
      parsedDate.toDate() >= minDate &&
      parsedDate.toDate() <= eighteenYearAgo
    ) {
      // Введенная дата допустима, обновите состояние
      const setDateValue = parsedDate.toDate();
      setDate(setDateValue);
      onChange(setDateValue);
    } else {
      // Введенная дата недопустима
      onChange(null);
      console.error("Введена недопустимая дата.");
    }
  };

  console.log(props);

  return (
    <div className={styles.InputDateWithCalendar} ref={ref}>
      <div
        className={clsx({
          [styles.InputBox]: true,
          [styles.InputBox_required]: error,
          [className]: !!className,
        })}
      >
        <InputMask
          onFocus={() => setShowCalendar(false)}
          className={styles.InputBox__input}
          mask="99.99.9999"
          placeholder="Укажите дату рождения"
          {...props}
          value={string as any}
          inputMode="numeric"
          onChange={(v) => onChangeInput(v.target.value)}
        />
        <div
          className={styles.InputBox__icon}
          onClick={() => setShowCalendar((prevState) => !prevState)}
        >
          <BaseIcon
            viewBox="0 0 20 20"
            height="20"
            width="20"
            icon="CALENDAR"
            fill="none"
          />
        </div>
      </div>

      <Calendar
        onClickDay={() => setShowCalendar(false)}
        className={clsx({
          [styles.Calendar]: true,
          [styles.Calendar__show]: showCalendar,
        })}
        locale={"ru"}
        onChange={onChangeCalendar}
        value={date}
        minDate={minDate}
        maxDate={eighteenYearAgo}
        nextLabel={<NextIcon />}
        next2Label={<NextIcon2 />}
        prevLabel={<PrevIcon />}
        prev2Label={<PrevIcon2 />}
      />
    </div>
  );
};

export default InputDateWithCalendar;
