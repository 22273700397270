import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { ChatMessages } from "./ChatMessages";
import { ChatControls } from "./ChatControls";
import { BaseLoader } from "src/components/base/BaseLoader";
import { BaseButton } from "src/components/base/BaseButton";
import { RoutesEnum } from "src/common/enums";

import {
  fetchUserIsOnlyEvents,
  selectUserEvents,
} from "src/redux/features/user/userSlice";
import styles from "./ChatBot.module.scss";

export default function ChatBot() {
  const { isOnlyEvents, loading } = useSelector(selectUserEvents);
  const messagesRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(fetchUserIsOnlyEvents() as any);
    })();
  }, []);

  let content = null;

  if (loading) {
    content = <BaseLoader />;
  }

  if (!isOnlyEvents) {
    content = (
      <>
        <ChatMessages messagesRef={messagesRef} inputRef={inputRef} />
        <ChatControls messagesRef={messagesRef} inputRef={inputRef} />
      </>
    );
  } else {
    content = (
      <div className={styles.ChatBotPlaceholder}>
        <h2 className={styles.ChatBotPlaceholder__Title}>
          Привет! Я - бот Тёрки!
        </h2>
        <p className={styles.ChatBotPlaceholder__Text}>
          Заполни профиль, чтобы участвовать в матчах
        </p>
        <BaseButton
          className={styles.ChatBotPlaceholder__Button}
          onClick={() => {
            navigate(RoutesEnum.ACCOUNT);
          }}
          variant={"base-button-new-style-black"}
        >
          Заполнить профиль
        </BaseButton>
      </div>
    );
  }

  return (
    <div
      className={clsx({
        [styles.ChatBot]: !isOnlyEvents,
        [styles.ChatBot_Full]: isOnlyEvents,
      })}
    >
      {content}
    </div>
  );
}
