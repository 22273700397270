import { useEffect, useRef } from "react";
import clsx from "clsx";

import { useWindowSize } from "src/hooks/useWindowSize";
import { IChipsFilter } from "../Contacts";
import styles from "./Chips.module.scss";

const chips: Array<{
  id: string;
  key: keyof IChipsFilter;
  val: IChipsFilter[keyof IChipsFilter];
  txt: string;
}> = [
  {
    id: "1",
    key: "dateOrder",
    val: "asc",
    txt: "Раньше",
  },
  {
    id: "2",
    key: "dateOrder",
    val: "desc",
    txt: "Позже",
  },
  {
    id: "3",
    key: "scoreOrder",
    val: "asc",
    txt: "Хуже",
  },
  {
    id: "4",
    key: "scoreOrder",
    val: "desc",
    txt: "Лучше",
  },
  {
    id: "5",
    key: "onlyFriends",
    val: false,
    txt: "Только друзья",
  },
];

interface IChipsProps {
  onChange: (
    key: keyof IChipsFilter,
    val: IChipsFilter[keyof IChipsFilter],
  ) => () => void;
  filters: IChipsFilter;
}

export default function Chips({ onChange, filters }: IChipsProps) {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const PADDING = 20;

  const [w] = useWindowSize();

  useEffect(() => {
    if (document.body.clientWidth >= 768 || !rootRef.current) return;
    rootRef.current.style.width = `${
      document.body.clientWidth - 1 - PADDING
    }px`;
  }, [rootRef, w]);

  return (
    <div className={styles.Chips} ref={rootRef}>
      {chips.map((chip) => (
        <Chip
          onClick={onChange(
            chip.key,
            typeof chip.val === "boolean"
              ? !filters["onlyFriends"]
              : chip.val === filters[chip.key]
              ? undefined
              : chip.val,
          )}
          active={
            typeof filters[chip.key] === "boolean"
              ? !!filters[chip.key]
              : filters[chip.key] === chip.val
          }
          key={chip.id}
        >
          {chip.txt}
        </Chip>
      ))}
    </div>
  );
}

interface IChipProps {
  children: React.ReactNode;
  onClick: () => void;
  active: boolean;
}

function Chip(props: IChipProps) {
  return (
    <div
      className={clsx({
        [styles.Chip]: true,
        [styles.Chip_Active]: props.active,
      })}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
