import clsx from "clsx";

import { Button } from "src/components/base/Button";

import styles from "./EventPreview.module.scss";
import { useSignupForm } from "src/hooks/useSignupForm";

export type ButtonSwitchType = {
  className?: string;
  isComplited: boolean;
  isOwner: boolean;
  onGoToDetails: () => void;
  onOpenTelegramModal: () => void;
  onGoEvents: () => void;
  eventId: string;
  isHaveRegLink?: boolean;
  isPreview?: boolean;
};

export default function EventButtonSwitch({
  className = "",
  ...rest
}: ButtonSwitchType) {
  const { deleteSignupFormState } = useSignupForm(); // TODO удалить по всему проекту

  const handleClick = (e: React.MouseEvent, callback: () => void) => {
    e.stopPropagation();
    e.preventDefault();
    callback();
    deleteSignupFormState();
  };

  const buttonProps = {
    className: clsx(styles.EventButtonSwitch, className && className),
    variant: "contained_nuar",
  } as const;

  if (rest.isOwner) {
    return (
      <>
        <Button
          {...buttonProps}
          onClick={(e) => handleClick(e, rest.onGoToDetails)}
        >
          Детали
        </Button>
      </>
    );
  } else if (rest.isComplited) {
    return <></>;
  } else if (!rest.isOwner && rest.isHaveRegLink) {
    return (
      <>
        <Button
          {...buttonProps}
          onClick={(e) => handleClick(e, rest.onOpenTelegramModal)}
        >
          Регистрация
        </Button>
      </>
    );
  } else if (!rest.isOwner && !rest.isHaveRegLink && rest.isPreview) {
    return (
      <>
        <Button
          {...buttonProps}
          onClick={(e) => handleClick(e, rest.onGoEvents)}
        >
          Подробнее
        </Button>
      </>
    );
  } else {
    return <></>;
  }
}
