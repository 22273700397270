import { PollLayout } from "../layouts/PollLayout";
import { PollHeader } from "../components/PollHeader";
import { CategoryForm } from "../components/CategoryForm";
import { RegistrationStep3Schema } from "../RegistrationStep3Schema";

const FORM_ID = "RegistrationStep3";

export default function RegistrationStep3() {
  console.count("RegistrationStep3");
  return (
    <PollLayout
      formId={FORM_ID}
      head={
        <PollHeader
          title="Какая область вам интересна?"
          text="Выберите минимум 1 вариант"
        />
      }
      form={
        <CategoryForm
          validationSchema={RegistrationStep3Schema}
          category="interests"
          nextStep={4}
          formId={FORM_ID}
        />
      }
    />
  );
}
