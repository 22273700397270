import clsx from "clsx";

import styles from "./Dialog.module.scss";

interface IProps {
  className?: string;
  children: React.ReactNode;
}

export default function Dialog({ className, children }: IProps) {
  return (
    <dialog className={styles.DialogOverflow} open>
      <div className={clsx(styles.Dialog, className)}>{children}</div>
    </dialog>
  );
}
