import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import clsx from "clsx";

import { InputDateWithCalendar, BaseInputSocials } from "src/components/base";
import { CategorySelector } from "src/content/CategorySelector";
import { SubscribeMeets } from "src/content";
import { ImageUploader } from "src/content/ImageUploader";
import { PollLayout } from "../layouts/PollLayout";
import { BaseSelect } from "src/components/base/BaseSelect";
import { PollHeader } from "../components/PollHeader";
import { Checkbox } from "../components/Checkbox";
import BaseCheckboxItem from "src/components/base/BaseCheckboxItem/BaseCheckboxItem";
import AgeSelector from "src/content/AgeSelector/AgeSelector";

import { useFormState, UseFormStateReturn } from "src/hooks/useFormState";
import {
  useAuthControllerSignupMutation,
  useAuthControllerValidateTelegramMutation,
} from "src/redux/requests/auth.slice";
import { RegistrationStep6Schema } from "../RegistrationStep6Schema";
import {
  resetForm,
  IPollInformation,
  selectForm,
} from "src/redux/features/poll/pollSlice";
import styles from "./RegistrationStep6.module.scss";
import { store } from "src/redux/store";
import { RegistrationStep1Schema } from "../RegistrationStep1Schema";
import { showToastError } from "src/common/functions/showToastError";
import { RegistrationStep2Schema } from "../RegistrationStep2Schema";
import { RegistrationStep3Schema } from "../RegistrationStep3Schema";
import { RegistrationStep4Schema } from "../RegistrationStep4Schema";
import { RegistrationStep5Schema } from "../RegistrationStep5Schema";
import { useNavigate } from "react-router-dom";
import {
  delFromStorage,
  getFromStorage,
  setToStorage,
} from "src/common/local-storage";
import { useAuth } from "src/hooks/useAuth";
import { TextArea } from "../components/Input/textarea";
import { addToken } from "src/redux/features/user/userSlice";
import { BaseMaskedInput } from "src/components/base/BaseInputSocials/BaseInputSocials";

const FORM_ID = "RegistrationStep6";

export default function RegistrationStep6() {
  return (
    <PollLayout
      formId={FORM_ID}
      head={<PollHeader title="Профиль" />}
      form={<RegistrationForm formId={FORM_ID} />}
    />
  );
}

function saveStepToStorage<T>(data: T) {
  try {
    const copyData: any = Object.assign({}, data);
    if (copyData.avatar) copyData.avatar = null;
    setToStorage("INFORMATION", copyData);
  } catch (e) {
    console.error("ERROR STRINGIFY JSON: ", e);
  }
}

function getStepFromStorage() {
  try {
    return getFromStorage("INFORMATION");
  } catch (e) {
    console.error("ERROR PARSE JSON");
  }
}

function delStepFromLocalStorage() {
  delFromStorage("INFORMATION");
}

function RegistrationForm({ formId }: { formId: string }) {
  const defaultValues = useSelector(
    selectForm("information"),
  ) as IPollInformation;

  const formState = useFormState<IPollInformation>({
    validationSchema: RegistrationStep6Schema,
    defaultValues: getStepFromStorage() ?? defaultValues,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    saveStepToStorage(formState.state);
  }, [formState.state]);

  const createFormData = (signupDto: any): FormData => {
    const formData = new FormData();

    Object.entries(signupDto).forEach(([key, value]) => {
      if (value instanceof File) {
        // Добавляем файл (avatar)
        formData.append(key, value);
      } else if (typeof value === "object" && value !== null) {
        // Преобразуем вложенные объекты в JSON
        formData.append(key, JSON.stringify(value));
      } else if (value !== undefined && value !== null) {
        // Добавляем простые примитивы
        formData.append(key, value as string);
      }
    });

    return formData;
  };

  const { handleAuth } = useAuth(); // TODO ЗАМЕНИТ ВЕЗДЕ НА redux
  const [telegramAlreadyUses] = useAuthControllerValidateTelegramMutation();
  const [signup] = useAuthControllerSignupMutation();
  const navigate = useNavigate();

  const onSubmit = async (data: IPollInformation) => {
    try {
      const result = await telegramAlreadyUses({
        validateTelegramDto: {
          telegram: data.socials.telegram,
        },
      }).unwrap();

      if (result.isExists) {
        showToastError("Пользователь с таким телеграм уже зарегестрирован");
        return;
      }
    } catch (e) {
      console.error("telegramAlreadyUses: ", e);
      showToastError("Ошибка в проверке телеграм");
      return;
    }

    const globalState = store.getState()["poll"]["form"];

    try {
      await RegistrationStep1Schema.parseAsync(globalState.intro);
    } catch (e) {
      console.error("RegistrationStep1Schema: ", e);
      showToastError("Ошибка в проверке 1 шага регистрации");
      return;
    }

    try {
      await RegistrationStep2Schema.parseAsync(globalState.hobbies);
    } catch (e) {
      console.error("RegistrationStep2Schema: ", e);
      showToastError("Ошибка в проверке 2 шага регистрации");
      return;
    }

    try {
      await RegistrationStep3Schema.parseAsync(globalState.interests);
    } catch (e) {
      console.error("RegistrationStep3Schema: ", e);
      showToastError("Ошибка в проверке 3 шага регистрации");
      return;
    }

    try {
      await RegistrationStep4Schema.parseAsync(globalState.needs);
    } catch (e) {
      console.error("RegistrationStep4Schema: ", e);
      showToastError("Ошибка в проверке 4 шага регистрации");
      return;
    }

    try {
      await RegistrationStep5Schema.parseAsync(globalState.connections);
    } catch (e) {
      console.error("RegistrationStep5Schema: ", e);
      showToastError("Ошибка в проверке 5 шага регистрации");
      return;
    }

    if (!data.isAbroad && !data.city) {
      showToastError("Укажите город");
      return;
    }

    const dto = {
      // step 1
      firstName: globalState.intro.firstName,
      lastName: globalState.intro.lastName,
      email: globalState.intro.email,
      password: globalState.intro.password,
      // step 2
      hobbies: globalState.hobbies,
      // step 3
      interests: globalState.interests,
      // step 4
      needs: globalState.needs,
      // step 5
      connections: globalState.connections,
      // step 6
      aboutMe: data.aboutMe,
      aboutInterlocutor: "aboutInterlocutor", // TODO убрать на клиенте и на бэке
      gender: data.gender,
      birthday: data.birthday?.toString(),
      city: data.city ?? null,
      poll: {
        age: data.interlocutorAge,
        communication: {
          proffessional: data.activity.proffessional,
          chat: data.activity.chat,
          romantic: data.activity.romantic,
        },
        gender: data.interlocutorGender,
      },
      socials: data.socials,
      avatar: data.avatar as any,
      isNotifyByEvents: data.isNotifyByEvents,
      isAbroad: data.isAbroad,
    };

    try {
      const { accessToken } = await signup({
        signupDto: createFormData(dto) as any,
      }).unwrap();

      // TODO НАДО ХРАНИТЬ access token в памяти не в localstorage и ориентироваться на рефреш токен
      // (если есть и он валиден (берез из кук на сервере) то обновляем access иначе сбрасываем)
      setToStorage("ACCESS_TOKEN", accessToken);
      delStepFromLocalStorage();
      dispatch(addToken(accessToken));
      dispatch(resetForm());
      handleAuth(true);
      navigate("/account");
    } catch (e: any) {
      console.error("Signup: ", e);

      if (e?.data?.message) {
        showToastError(e?.data?.message);
        return;
      }

      showToastError("Ошибка регистрации");
      return;
    }
  };

  console.count("RegistrationForm");

  return (
    <form
      className={styles.RegistrationForm}
      onSubmit={formState.form.handleSubmit(onSubmit)}
      id={formId}
    >
      <FormBlock
        className={styles.RegistrationForm__Block}
        title="Расскажите о себе"
      >
        <AboutMeFields
          className={styles.RegistrationForm__Fields}
          {...formState}
        />
      </FormBlock>
      <FormBlock
        className={styles.RegistrationForm__Block}
        title="Загрузите фото профиля"
      >
        <AvatarFields
          className={styles.RegistrationForm__Fields}
          {...formState}
        />
      </FormBlock>
      <FormBlock
        className={styles.RegistrationForm__Block}
        title="Категории общения"
        text="Укажите, насколько вы заинтересованы в категории по шкале до 10 и свои предпочтения"
      >
        <CategoryFields
          className={styles.RegistrationForm__Fields}
          {...formState}
        />
      </FormBlock>
      <FormBlock
        className={styles.RegistrationForm__Block}
        title="Как с вами может связаться ваш собеседник"
      >
        <SocialFields
          className={styles.RegistrationForm__Fields}
          {...formState}
        />
      </FormBlock>
      <SubscribeMeets
        className={styles.RegistrationForm__Block}
        onChange={formState.onChange("isNotifyByEvents")}
        checked={formState.state.isNotifyByEvents}
      />
    </form>
  );
}

// BLOCKS
function FormBlock({
  className,
  children,
  title,
  text,
}: {
  className: string;
  children: React.ReactNode;
  title: string;
  text?: string;
}) {
  return (
    <div className={clsx(styles.FormBlock, className)}>
      <h3 className={styles.FormBlock__Title}>{title}</h3>
      {text && <p className={styles.FormBlock__Text}>{text}</p>}
      {children}
    </div>
  );
}

// PARTS OF THE FORM
type FieldsPropsType = UseFormStateReturn<IPollInformation> & {
  className: string;
};

function AboutMeFields({
  className,
  getError,
  onChange,
  onBlur,
  errors,
  state,
  form,
}: FieldsPropsType) {
  console.count("AboutMeFields");
  return (
    <div className={clsx(styles.AboutMeFields, className)}>
      <TextArea
        onChange={onChange("aboutMe")}
        onBlur={onBlur("aboutMe")}
        error={!!getError("aboutMe")}
        value={state.aboutMe}
        placeholder="Расскажите кратко"
        maxLength={500}
      />
      <BaseSelect
        placeholder="Пол"
        onChange={(gender) => {
          onChange("gender")(gender);
          form.clearErrors("gender");
        }}
        error={!!errors.gender}
        defaultValue={state.gender}
        options={["Мужской", "Женский"]}
      />
      <InputDateWithCalendar
        {...form.register("birthday")}
        onChange={(v) => {
          onChange("birthday")(v);
          form.clearErrors("birthday");
        }}
        error={!!getError("birthday")}
        defaultValue={state.birthday ?? ""}
      />
      <BaseSelect
        placeholder="Город"
        onChange={onChange("city")}
        error={!!errors.city}
        options={["Калининград", "Москва", "Санк-Петербург"]}
        disabled={state.isAbroad}
        defaultValue={state.city}
      />
      <div className={styles.AboutMeFields__CheckboxLabel}>
        <Checkbox
          onChange={(v) => {
            onChange("isAbroad")(v);
            onChange("city")(null);
            form.clearErrors("city");
          }}
          checked={state.isAbroad}
        />
        Нахожусь за границей
      </div>
    </div>
  );
}

function AvatarFields({ className, state, onChange }: FieldsPropsType) {
  console.count("AvatarFields");
  return (
    <div className={clsx(styles.AvatarFields, className)}>
      {state.avatar ? (
        <div className={styles.AvatarPreview}>
          <img
            className={styles.AvatarPreview__Image}
            height={240}
            width={390}
            src={URL.createObjectURL(state.avatar)} // Превью изображения
            alt="Preview"
          />
          <div className={styles.AvatarPreview__Overflow}>
            <div
              className={styles.AvatarPreview__Button}
              onClick={() => onChange("avatar")(null)}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.512 8.25L13.1947 16.5M8.80529 16.5L8.48798 8.25M17.6253 5.30802C17.9388 5.35536 18.2512 5.40601 18.5625 5.45993M17.6253 5.30802L16.6465 18.0332C16.5638 19.1077 15.6678 19.9375 14.5901 19.9375H7.40994C6.33221 19.9375 5.43617 19.1077 5.35351 18.0332L4.37466 5.30802M17.6253 5.30802C16.5745 5.14932 15.5114 5.02778 14.4375 4.9448M3.4375 5.45993C3.74878 5.40601 4.06117 5.35536 4.37466 5.30802M4.37466 5.30802C5.42554 5.14932 6.48862 5.02778 7.5625 4.9448M14.4375 4.9448V4.105C14.4375 3.02392 13.6027 2.12138 12.5222 2.08681C12.0168 2.07065 11.5093 2.0625 11 2.0625C10.4907 2.0625 9.98321 2.07065 9.4778 2.08681C8.39727 2.12138 7.5625 3.02392 7.5625 4.105V4.9448M14.4375 4.9448C13.3032 4.85714 12.1568 4.8125 11 4.8125C9.8432 4.8125 8.69682 4.85714 7.5625 4.9448"
                  stroke="#1A1A1A99"
                  strokeOpacity="0.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      ) : (
        <ImageUploader
          className={styles.ImageUploader}
          onChange={onChange("avatar")}
          isBigImage={false}
          isLoading={false}
          placeholder="Рекомендуемое разрешение: 400 х 400 px"
        />
      )}
    </div>
  );
}

function CategoryFields({
  className,
  onChange,
  errors,
  state,
  form,
}: FieldsPropsType) {
  const hideAge = useMemo(() => {
    if (state.activity.romantic > 0) {
      return false;
    }

    onChange("interlocutorAge")({
      from: 18,
      till: 56,
    });
    return true;
  }, [state.activity.romantic]);

  return (
    <div className={clsx(styles.CategoryFields, className)}>
      <div className={styles.CategoryFields__Block}>
        <div className={styles.CategoryFields__Row}>
          <p
            className={clsx(
              styles.CategoryFields__Label,
              styles.CategoryFields__Label_Bold,
            )}
          >
            Профессиональные
          </p>
          <CategorySelector
            classNameWrapper={styles.CategoryFields__Slider}
            onChange={(_, v) => onChange("activity.proffessional")(+v)}
            value={state.activity.proffessional}
            label="professional"
          />
        </div>
        <div className={styles.CategoryFields__Row}>
          <p
            className={clsx(
              styles.CategoryFields__Label,
              styles.CategoryFields__Label_Bold,
            )}
          >
            Просто общение
          </p>
          <CategorySelector
            classNameWrapper={styles.CategoryFields__Slider}
            onChange={(_, v) => onChange("activity.chat")(+v)}
            value={state.activity.chat}
            label="chat"
          />
        </div>
        <div className={styles.CategoryFields__Row}>
          <p
            className={clsx(
              styles.CategoryFields__Label,
              styles.CategoryFields__Label_Thin,
            )}
          >
            Я готов(-а) общаться с:
          </p>
          <div className={styles.CategoryFields__Checkboxes}>
            <BaseCheckboxItem
              className={styles.CategoryFields__Checkbox}
              answerTitle={"Мужчина"}
              answerChecked={state.interlocutorGender.male}
              onChange={(v) => {
                onChange("interlocutorGender.male")(v);
                if (v) form.clearErrors("interlocutorGender.female");
                form.trigger("interlocutorGender");
              }}
              error={!!errors.interlocutorGender?.male}
            />
            <BaseCheckboxItem
              className={styles.CategoryFields__Checkbox}
              answerTitle={"Женщина"}
              answerChecked={state.interlocutorGender.female}
              onChange={(v) => {
                onChange("interlocutorGender.female")(v);
                if (v) form.clearErrors("interlocutorGender.male");
                form.trigger("interlocutorGender");
              }}
              error={!!errors.interlocutorGender?.female}
            />
          </div>
        </div>
      </div>

      <div className={styles.CategoryFields__Block}>
        <div className={styles.CategoryFields__Row}>
          <p
            className={clsx(
              styles.CategoryFields__Label,
              styles.CategoryFields__Label_Bold,
            )}
          >
            Романтические
          </p>
          <p className={styles.CategoryFields__Romantic}>
            <svg
              className={styles.CategoryFields__RomanticIcon}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 13C7.88393 13 7.78571 12.9609 7.70536 12.8828L3.52679 8.96354C3.48214 8.92882 3.42076 8.8724 3.34263 8.79427C3.26451 8.71615 3.14062 8.574 2.97098 8.36784C2.80134 8.16168 2.64955 7.95009 2.51562 7.73307C2.3817 7.51606 2.26228 7.25347 2.15737 6.94531C2.05246 6.63715 2 6.33767 2 6.04688C2 5.09201 2.28348 4.34549 2.85045 3.80729C3.41741 3.2691 4.20089 3 5.20089 3C5.47768 3 5.76004 3.04666 6.04799 3.13997C6.33594 3.23329 6.60379 3.35916 6.85156 3.51758C7.09933 3.676 7.3125 3.82465 7.49107 3.96354C7.66964 4.10243 7.83929 4.25 8 4.40625C8.16071 4.25 8.33036 4.10243 8.50893 3.96354C8.6875 3.82465 8.90067 3.676 9.14844 3.51758C9.39621 3.35916 9.66406 3.23329 9.95201 3.13997C10.24 3.04666 10.5223 3 10.7991 3C11.7991 3 12.5826 3.2691 13.1496 3.80729C13.7165 4.34549 14 5.09201 14 6.04688C14 7.00608 13.4888 7.98264 12.4665 8.97656L8.29464 12.8828C8.21429 12.9609 8.11607 13 8 13Z"
                fill="#797983"
              />
            </svg>
            Сердечко рядом с именем означает, что собеседник не против
            романтического общения
          </p>
          <CategorySelector
            classNameWrapper={styles.CategoryFields__Slider}
            onChange={(_, v) => onChange("activity.romantic")(+v)}
            value={state.activity.romantic}
            label="romantic"
          />
        </div>
        <div
          className={styles.CategoryFields__Row}
          style={hideAge ? { display: "none" } : undefined}
        >
          <p
            className={clsx(
              styles.CategoryFields__Label,
              styles.CategoryFields__Label_Bold,
            )}
          >
            Укажите возраст
          </p>
          <AgeSelector
            classNameWrapper={styles.CategoryFields__Slider}
            value={state.interlocutorAge}
            onChange={(_, v) =>
              onChange("interlocutorAge")({
                from: (v as number[])[0],
                till: (v as number[])[1],
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

function SocialFields({ className, onChange, errors, state }: FieldsPropsType) {
  console.count("SocialFields");
  console.log(state.socials.whatsapp);
  return (
    <div className={clsx(styles.SocialFields, className)}>
      <BaseInputSocials
        className={styles.SocialFields__Input}
        placeholder="@username"
        onChange={(e) => onChange("socials.telegram")(e.target.value)}
        variant={"TG"}
        value={state.socials.telegram}
        error={!!errors.socials?.telegram}
        maxLength={100}
      />
      <BaseInputSocials
        className={styles.SocialFields__Input}
        placeholder="VK"
        onChange={(e) => onChange("socials.vk")(e.target.value)}
        variant={"VK"}
        value={state.socials.vk}
        error={!!errors.socials?.vk}
        maxLength={100}
      />
      <BaseMaskedInput
        className={styles.SocialFields__Input}
        // placeholder="+79998887766"
        onChange={onChange("socials.whatsapp")}
        variant={"WA"}
        value={state.socials.whatsapp}
        error={!!errors.socials?.whatsapp}
        // maxLength={100}
      />
      <BaseInputSocials
        className={styles.SocialFields__Input}
        placeholder="Facebook"
        onChange={(e) => onChange("socials.facebook")(e.target.value)}
        variant={"FB"}
        value={state.socials.facebook}
        error={!!errors.socials?.facebook}
        maxLength={100}
      />
      <BaseInputSocials
        className={styles.SocialFields__Input}
        placeholder="Instagram"
        onChange={(e) => onChange("socials.instagram")(e.target.value)}
        variant={"IG"}
        value={state.socials.instagram}
        error={!!errors.socials?.instagram}
        maxLength={100}
      />
    </div>
  );
}
