import clsx from "clsx";
import moment from "moment";

import { Image } from "../../../../../content/Image";

import { ContactVm, LatestMatchVm } from "src/redux/requests/contacts.slice";
import { scoresDictionary } from "src/common/scores";
import female from "./female.svg";
import male from "./male.svg";
import styles from "./ContactHeader.module.scss";

interface IContactHeaderProps {
  className?: string;
  showStatus?: boolean;
  data: {
    avatar?: {
      fileName: string;
      path: string;
    } | null;
    gender: string;
    lastScores: ContactVm["lastScores"];
    latestMatch: LatestMatchVm;
  };
}

export default function ContactHeader({
  className,
  showStatus = true,
  data,
}: IContactHeaderProps) {
  return (
    <div className={clsx(styles.CardHeader, className)}>
      <ContactScore
        avatar={data?.avatar?.path}
        gender={data.gender}
        scores={data.lastScores}
      />
      {showStatus && (
        <ContactStatus
          status={data.latestMatch.status}
          date={data.latestMatch.createdAt}
        />
      )}
    </div>
  );
}

interface IContactScoreProps {
  avatar?: string;
  gender: string;
  scores: number[];
}

function ContactScore({ avatar, gender, scores }: IContactScoreProps) {
  return (
    <div className={styles.ContactScore}>
      <Image
        defaultImage={gender === "Мужской" ? male : female}
        height={55}
        width={55}
        src={avatar}
      />
      {scores.map(
        (score, i) =>
          !!score && (
            <Image
              height={55}
              width={55}
              src={scoresDictionary[score]}
              key={i}
            />
          ),
      )}
    </div>
  );
}

interface IContactStatusProps {
  status: "NEW" | "MET" | "DID_NOT_MET";
  date: string;
}

const statuses = {
  NEW: {
    val: "Новое",
    cls: styles.ContactStatus__Status_Purple,
  },
  MET: {
    val: "Встреча прошла",
    cls: styles.ContactStatus__Status_Green,
  },
  DID_NOT_MET: {
    val: "Не встретились",
    cls: styles.ContactStatus__Status_Orange,
  },
};

function ContactStatus({ status, date }: IContactStatusProps) {
  return (
    <div className={styles.ContactStatus}>
      <div className={clsx(styles.ContactStatus__Status, statuses[status].cls)}>
        {statuses[status].val}
      </div>
      <div className={styles.ContactStatus__Date}>{formatDate(date)}</div>
    </div>
  );
}

function formatDate(value: string) {
  return moment(value).format("DD.MM.YY");
}
