import { useEffect, useLayoutEffect } from "react";
import { z } from "zod";

import { BaseTypography } from "../../../components/base/BaseTypography";
import { Stack } from "../../../components/base/Stack";
import { Input } from "src/pages/signup-routes/components/Input";

import { useFormState } from "src/hooks/useFormState";
import { getMe } from "../../../api/hooks/personsHooks";
import styles from "../styles.module.scss";
import { Label } from "../Label";
import { useFile } from "src/content/ImageUploader/ImageUploader";
import { usePersonsControllerCreateAvatarMutation } from "src/redux/requests/persons.slice";
import { showToastError } from "src/common/functions/showToastError";
import clsx from "clsx";

const validationSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  gender: z.string(),
  birthday: z.date().or(z.string()),
  email: z.string(),
  city: z.string(),
  avatar: z
    .object({
      path: z.string(),
      filePath: z.string(),
    })
    .nullable(),
});

type FormType = z.infer<typeof validationSchema>;

const defaultValues: FormType = {
  firstName: "",
  lastName: "",
  gender: "",
  birthday: "",
  email: "",
  city: "",
  avatar: null,
};

const AccountCredentials = () => {
  const { onChange, state, form } = useFormState<FormType>({
    validationSchema,
    defaultValues,
  });

  //с пустым массивом зависимостей, данные не обновляемые
  useLayoutEffect(() => {
    (async () => {
      const data = await getMe();

      if (!data) return;

      form.reset(data);
    })();
  }, []);

  const birthday = state.birthday
    ? new Date(state.birthday).toLocaleDateString()
    : "";

  const [uploadAvatar, { isLoading }] =
    usePersonsControllerCreateAvatarMutation();

  const { inputFileRef, onChangeHandler, onUploadByClick } = useFile(
    async (files) => {
      console.log("FILES: ", files);
      const formData = new FormData();
      formData.append("avatar", files[0] as any);
      try {
        const avatar: any = await uploadAvatar({
          createAvatarDto: formData as any,
        }).unwrap();

        if (!avatar) return;
        onChange("avatar")({
          fileName: avatar.fileName,
          path: avatar?.path,
        } as any);
      } catch (e) {
        showToastError("Не удалось загрузить аватар");
      }
    },
  );

  return (
    <Stack space={30}>
      <span className={styles.Poll__line}>
        <BaseTypography
          variant={"h2"}
          format={"landing-title-lvl-4"}
          className={styles.Account__title}
        >
          Личные данные
        </BaseTypography>
      </span>
      <form className={styles.AccountForm}>
        <div className={styles.AccountForm__Avatar}>
          {state.avatar ? (
            <div
              onClick={onUploadByClick}
              className={clsx(
                styles.AccountForm__AvatarCircle,
                styles.AccountForm__AvatarCircle_Fill,
              )}
            >
              <span className={styles.AccountForm__AvatarText}>
                Заменить
                <br /> фото
              </span>
              <input
                className={styles.AccountForm__AvatarInput}
                disabled={isLoading}
                onChange={onChangeHandler}
                multiple={true}
                hidden
                accept="image/jpg, image/jpeg, image/png"
                type="file"
                ref={inputFileRef}
              />
              <img
                className={clsx(
                  styles.AccountForm__AvatarCircle,
                  styles.AccountForm__AvatarImage,
                )}
                src={state.avatar.path}
                alt="Avatar"
              />
            </div>
          ) : (
            <div
              className={clsx(
                styles.AccountForm__AvatarCircle,
                styles.AccountForm__AvatarCircle_Empty,
              )}
              onClick={onUploadByClick}
            >
              <input
                className={styles.AccountForm__AvatarInput}
                disabled={isLoading}
                onChange={onChangeHandler}
                multiple={true}
                hidden
                accept="image/jpg, image/jpeg, image/png"
                type="file"
                ref={inputFileRef}
              />
            </div>
          )}
        </div>
        <div className={styles.AccountForm__Fields}>
          <Label title="Имя">
            <Input
              disabled={true}
              value={state.firstName}
              onChange={() => {}}
            />
          </Label>
          <Label title="Фамилия">
            <Input disabled={true} value={state.lastName} onChange={() => {}} />
          </Label>
          <Label title="Пол">
            <Input disabled={true} value={state.gender} onChange={() => {}} />
          </Label>
          <Label title="Дата рождения">
            <Input disabled={true} onChange={() => {}} value={birthday} />
          </Label>
          <Label title="Email">
            <Input disabled={true} value={state.email} onChange={() => {}} />
          </Label>
          <Label title="Город">
            <Input
              disabled={true}
              value={state.city ?? "За границей"}
              onChange={() => {}}
            />
          </Label>
        </div>
      </form>
    </Stack>
  );
};

export default AccountCredentials;
