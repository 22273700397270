import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

type ModalPayloadMap = {
  "confirm-email": { email: string };
};

export type ModalType = keyof ModalPayloadMap | null;
export type ModalDataType<T extends ModalType> = T extends keyof ModalPayloadMap
  ? ModalPayloadMap[T]
  : null;

interface IModalSliceState<T extends ModalType> {
  type: T;
  data: ModalDataType<T>;
}

const initialState: IModalSliceState<null> = {
  type: null,
  data: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal<T extends ModalType>(
      state: IModalSliceState<T>,
      action: PayloadAction<{ type: T; data: ModalDataType<T> }>,
    ) {
      state.type = action.payload.type;
      state.data = action.payload.data;
    },
    closeModal(state) {
      state.type = null;
      state.data = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const selectModal = (state: RootState) => state.modal;

export default modalSlice.reducer;
