import { PollLayout } from "../layouts/PollLayout";
import { PollHeader } from "../components/PollHeader";
import { CategoryForm } from "../components/CategoryForm";
import { RegistrationStep5Schema } from "../RegistrationStep5Schema";

const FORM_ID = "RegistrationStep5";

export default function RegistrationStep5() {
  console.count("RegistrationStep5");
  return (
    <PollLayout
      formId={FORM_ID}
      head={
        <PollHeader
          title="С какими людьми вы бы хотели встретиться?"
          text="Выберите минимум 1 вариант"
        />
      }
      form={
        <CategoryForm
          validationSchema={RegistrationStep5Schema}
          category="connections"
          nextStep={6}
          formId={FORM_ID}
        />
      }
    />
  );
}
