import { useCallback } from "react";
import {
  DefaultValues,
  UseFormReturn,
  FieldValues,
  FieldErrors,
  PathValue,
  useForm,
  Path,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

type ConfigType<T> = {
  validationSchema: z.ZodObject<any> | z.ZodEffects<z.ZodObject<any>>;
  defaultValues: DefaultValues<T> | undefined;
};

export interface UseFormStateReturn<T extends FieldValues> {
  getError: (key: Path<T>) => string | undefined;
  onChange: (key: Path<T>) => (val: PathValue<T, Path<T>>) => void;
  onBlur: (key: Path<T>) => () => void;
  errors: FieldErrors<T>;
  state: T;
  form: UseFormReturn<T>;
}

export function useFormState<T extends FieldValues>({
  validationSchema,
  defaultValues,
}: ConfigType<T>) {
  const form = useForm<T>({
    defaultValues,
    resolver: zodResolver(validationSchema) as any,
    mode: "onBlur",
  });

  const errors = form.formState.errors;
  const state = form.watch();

  const getError = useCallback(
    (key: Path<T>) => {
      return key in errors && errors[key]?.message
        ? errors[key]?.message
        : undefined;
    },
    [errors],
  );

  const onChange = useCallback(
    (key: Path<T>) => (val: PathValue<T, Path<T>>) => {
      form.setValue(key, val);
    },
    [],
  );

  const onBlur = useCallback(
    (key: Path<T>) => () => {
      form.trigger(key);
    },
    [],
  );

  return {
    getError,
    onChange,
    onBlur,
    errors,
    state,
    form,
  };
}
