import { useState } from "react";
import clsx from "clsx";

import styles from "./Image.module.scss";

interface IImageProps {
  className?: string;
  src?: string;
  height: number;
  width: number;
  defaultImage?: string;
  alt?: string;
}

export default function Image({
  className,
  src,
  height,
  width,
  defaultImage,
  alt = "Аватар пользователя",
}: IImageProps) {
  const [isLoading, setLoading] = useState(true);
  return (
    <div className={clsx(styles.Image, className)}>
      <img
        className={clsx(
          {
            [styles.Image__Preview]: true,
            [styles.Image__Preview_Hide]: isLoading,
          },
          className,
        )}
        loading="lazy"
        height={height}
        width={width}
        onLoad={() => setLoading((prev) => !prev)}
        alt={alt}
        src={src ?? defaultImage}
      />
      <div
        className={clsx(
          {
            [styles.Image__Skeleton]: true,
            [styles.Image__Skeleton_Hide]: !isLoading,
          },
          className,
        )}
      />
    </div>
  );
}
