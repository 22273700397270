import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { z } from "zod";

import { BaseButton } from "src/components/base/BaseButton";
import { Dialog } from "src/components/base/Dialog";
import { Input } from "src/pages/signup-routes/components/Input";

import {
  useAuthControllerConfirmCodeMutation,
  useAuthControllerSendConfirmationCodeMutation,
} from "src/redux/requests/auth.slice";
import { closeModal, ModalDataType } from "src/redux/features/modal/modalSlice";
import { showToastError } from "src/common/functions/showToastError";
import { useFormState } from "src/hooks/useFormState";
import { addStep } from "src/redux/features/poll/pollSlice";
import styles from "./ConfirmEmailModal.module.scss";

const FORM_ID = "ConfirmEmailModal";
const TIMER = 60;

interface IProps {
  data: ModalDataType<"confirm-email">;
}

export default function ConfirmEmailModal({ data }: IProps) {
  console.count("ConfirmEmailModal");
  return (
    <Dialog className={styles.ConfirmEmailModal}>
      <div className={styles.ConfirmEmailModal__Header}>
        <p className={styles.ConfirmEmailModal__Title}>
          Введите код, отправленный на{" "}
          <span className={styles.ConfirmEmailModal__Title_Bold}>
            {data.email}
          </span>
        </p>
        <p className={styles.ConfirmEmailModal__Subtitle}>
          Если письмо не пришло — проверьте спам
        </p>
      </div>
      <ConfirmEmailModalForm
        className={styles.ConfirmEmailModal__Form}
        formId={FORM_ID}
        data={data}
      />
      <ConfirmEmailModalFooter
        className={styles.ConfirmEmailModal__Footer}
        formId={FORM_ID}
        data={data}
      />
    </Dialog>
  );
}

const validationSchema = z.object({
  n1: z.string().min(1).max(1),
  n2: z.string().min(1).max(1),
  n3: z.string().min(1).max(1),
  n4: z.string().min(1).max(1),
});

type FormStateType = z.infer<typeof validationSchema>;

const defaultValues: FormStateType = {
  n1: "",
  n2: "",
  n3: "",
  n4: "",
};

interface IConfirmEmailModalFormProps {
  className?: string;
  formId: string;
  data: ModalDataType<"confirm-email">;
}

function ConfirmEmailModalForm({
  className,
  formId,
  data,
}: IConfirmEmailModalFormProps) {
  const { getError, onChange, onBlur, state, form } = useFormState({
    validationSchema,
    defaultValues,
  });

  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);
  const dispatch = useDispatch();
  const nums = Object.entries(state);

  const [confirmCode] = useAuthControllerConfirmCodeMutation();

  async function onSubmit(state: FormStateType) {
    const code = Object.values(state).join("");
    try {
      const isConfirmed = await confirmCode({
        confirmCodeDto: {
          email: data.email,
          code,
        },
      }).unwrap();

      if (isConfirmed) {
        dispatch(addStep(2));
        dispatch(closeModal());
      } else {
        showToastError("Неверный код");
      }
    } catch (e) {
      console.error("confirmCode: ", e);
      showToastError("Ошибка проверки кода");
    }
  }

  return (
    <form
      className={clsx(styles.ConfirmEmailModalForm, className)}
      onSubmit={form.handleSubmit(onSubmit)}
      id={formId}
    >
      {nums.map(([k, v], index) => (
        <Input
          ref={(el) => (inputsRef.current[index] = el)}
          className={styles.ConfirmEmailModalForm__Input}
          maxLength={1}
          onChange={(value) => {
            if (!/^\d*$/.test(value)) {
              return;
            }

            onChange(k as keyof FormStateType)(value);

            // Перенос фокуса только при заполнении текущего инпута
            if (value.length === 1 && inputsRef.current[index + 1]) {
              inputsRef.current[index + 1]?.focus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !state[k as keyof FormStateType]) {
              if (inputsRef.current[index - 1]) {
                inputsRef.current[index - 1]?.focus();
              }
            }

            // Обработка Ctrl+Z или Cmd+Z
            if ((e.ctrlKey || e.metaKey) && e.key === "z") {
              e.preventDefault();

              const keys = Object.keys(state);

              // Удаляем значение текущего инпута
              onChange(keys[index] as keyof FormStateType)("");

              // Переносим фокус на предыдущий инпут, если он есть
              if (inputsRef.current[index - 1]) {
                inputsRef.current[index - 1]?.focus();
              }

              // Проверяем все инпуты на валидность
              inputsRef.current.forEach((inputRef, i) => {
                if (inputRef) {
                  const currentValue = state[keys[i] as keyof FormStateType];

                  // Если в поле больше одного символа или не цифра
                  if (currentValue.length > 1 || !/^\d$/.test(currentValue)) {
                    const firstDigit = currentValue.match(/\d/)?.[0] || ""; // Берем первую цифру
                    onChange(keys[i] as keyof FormStateType)(firstDigit); // Обновляем состояние
                  }
                }
              });
            }
          }}
          onPaste={(e) => {
            e.preventDefault();
            const paste = e.clipboardData.getData("text");
            if (!/^\d{4}$/.test(paste)) {
              return; // Вставка только если ровно 4 цифры
            }

            const chars = paste.split("");

            // Очищаем все поля перед вставкой
            inputsRef.current.forEach((_, i) => {
              onChange(Object.keys(state)[i] as keyof FormStateType)("");
            });

            // Вставляем символы
            chars.forEach((char, i) => {
              if (inputsRef.current[i]) {
                onChange(Object.keys(state)[i] as keyof FormStateType)(char);
              }
            });

            // Фокус на последний инпут
            inputsRef.current[chars.length - 1]?.focus();

            // Очищаем ошибки
            form.clearErrors();
          }}
          onBlur={onBlur(k as keyof FormStateType)}
          error={getError(k as keyof FormStateType)}
          value={v}
          key={k}
        />
      ))}
    </form>
  );
}

interface IConfirmEmailModalFooterProps {
  className?: string;
  formId: string;
  data: ModalDataType<"confirm-email">;
}

function ConfirmEmailModalFooter({
  className,
  formId,
  data,
}: IConfirmEmailModalFooterProps) {
  const [timer, setTimer] = useState(TIMER);

  useEffect(() => {
    let t = setInterval(() => {
      setTimer((p) => (p === 0 ? p : --p));
    }, 1000);

    return () => {
      clearInterval(t);
    };
  }, []);

  const [sendCode] = useAuthControllerSendConfirmationCodeMutation();

  async function resendCode() {
    try {
      await sendCode({
        sendCodeDto: {
          email: data.email,
        },
      });

      setTimer(TIMER);
    } catch (e) {
      console.error("resendCode: ", e);
      showToastError("Не удалось отправить код!");
    }
  }

  console.count("ConfirmEmailModalFooter");

  return (
    <div className={clsx(styles.ConfirmEmailModalFooter, className)}>
      <p
        className={styles.ConfirmEmailModalFooter__Resend}
        onClick={resendCode}
      >
        {timer > 0
          ? `Отправить повторный код через: ${timer} секунд`
          : `Не пришёл код`}
      </p>
      <BaseButton
        className={styles.ConfirmEmailModalFooter__Button}
        variant="base-button-new-style-black"
        form={formId}
        type="submit"
      >
        Продолжить
      </BaseButton>
    </div>
  );
}
