import { QueryClient } from "react-query";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
// request.js
import {
  delFromStorage,
  getFromStorage,
  setToStorage,
} from "../common/local-storage";
import toast from "react-hot-toast/headless";

export const queryClient = new QueryClient({
  defaultOptions: {
    // queries: {
    //   refetchOnWindowFocus: false,
    // },
  },
});

export const baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL;

// optionaly add base url
const client = axios.create({ baseURL, withCredentials: true });

client.interceptors.request.use(
  (config) => {
    const accessToken = getFromStorage("ACCESS_TOKEN");

    if (accessToken && config.headers) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  async (config) => config,
  async (error) => {
    const config = error?.config;
    const status = error?.response?.status;

    if ((status === 403 || status === 401) && config.url === "/auth/refresh") {
      delFromStorage("ACCESS_TOKEN");
      // delFromStorage("CREDENTIALS");
    }

    if (status === 401 && !config._retry) {
      config._retry = true;

      try {
        const { data } = await axios({
          url: `/auth/refresh`,
          withCredentials: true,
          method: "POST",
          baseURL,
        });

        const accessToken = data.accessToken;

        if (data.access) {
          setToStorage("ACCESS_TOKEN", accessToken);
          config.headers["Authorization"] = `Bearer ${accessToken}`;
          return client.request(config);
        } else {
          return config;
        }
      } catch (e) {
        resetUserSession();
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  },
);

export const request = async <T>(options: AxiosRequestConfig<any>) => {
  const onSuccess = (response: AxiosResponse<T>) => response;

  const onError = (error: any) => {
    const data = error?.response?.data;

    if (data?.statusCode === 400 && data?.code === "BAD_REQUEST") {
      toast(data.message);
    }

    throw new Error(error);
  };

  try {
    const response = await client(options);

    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export function resetUserSession() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }

  delFromStorage("ACCESS_TOKEN");
  // delFromStorage("CREDENTIALS");
}

export const req = client;
