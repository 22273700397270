import {
  contacts as api,
  ContactsControllerUpdateStatusApiArg,
  ContactsControllerUpdateStatusApiResponse,
} from "./contacts.slice";
import {
  ContactsControllerGetAllApiArg,
  ContactsControllerGetAllApiResponse,
} from "./contacts.slice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    contactsControllerGetAll: build.query<
      ContactsControllerGetAllApiResponse,
      ContactsControllerGetAllApiArg
    >({
      query: (queryArg) => ({
        url: "/api/contacts",
        params: {
          dateOrder: queryArg.dateOrder,
          scoreOrder: queryArg.scoreOrder,
          onlyFriends: queryArg.onlyFriends,
          limit: queryArg.limit,
          page: queryArg.page,
        },
      }),
      // @ts-ignore
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Contacts" as const,
                id,
              })),
              { type: "Contacts", id: "LIST" },
            ]
          : [{ type: "Contacts", id: "LIST" }],
    }),
    contactsControllerUpdateStatus: build.mutation<
      ContactsControllerUpdateStatusApiResponse,
      ContactsControllerUpdateStatusApiArg
    >({
      query: (queryArg) => ({
        url: "/api/contacts/friendship",
        method: "PATCH",
        body: queryArg.applicationDto,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
        const { contactId, status } = arg.applicationDto;

        let patchResult: any;

        try {
          const state = getState();

          // Собираем все возможные состояние и обновляем кеш,
          // что бы состояние было одинаковым везде
          Object.values(state.api.queries).forEach((query: any) => {
            if (
              query.endpointName === "contactsControllerGetAll" &&
              query.data
            ) {
              const queryArg = query.originalArgs;

              // @ts-ignore
              patchResult = dispatch(
                api.util.updateQueryData(
                  // @ts-ignore
                  "contactsControllerGetAll",
                  queryArg,
                  (draft) => {
                    // @ts-ignore
                    const contact = draft.items.find(
                      // @ts-ignore
                      (item) => item.id === contactId,
                    );
                    if (contact) {
                      contact.friendship = status;
                    }
                  },
                ),
              );
            }
          });

          await queryFulfilled;
        } catch (e) {
          if (patchResult) {
            patchResult.undo();
          }
          console.error("Error updating contact status", e);
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useContactsControllerLeaveCommentMutation,
  useContactsControllerUpdateStatusMutation,
  useContactsControllerGetAllQuery,
} = injectedRtkApi;

export const usePaginatedContacts = (args: ContactsControllerGetAllApiArg) => {
  const { data, error, isLoading, isFetching } =
    useContactsControllerGetAllQuery(args, {
      skip: !args.page,
      refetchOnMountOrArgChange: true,
    });

  const pagination = {
    currentPage: args.page,
    totalPages: data?.totalPages || 0,
    hasNextPage: data ? args.page < data.totalPages : false,
    hasPreviousPage: data ? args.page > 1 : false,
  };

  return { data, error, isLoading, isFetching, pagination };
};
