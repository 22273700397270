import { FC, useEffect, useState } from "react";

import { ArrowDownIcon, TikIcon } from "../BaseIcons";
import { useModal } from "../../../hooks/useModal";
import "./index.scss";
import clsx from "clsx";
import { useCloseByOutsideClick } from "src/hooks/useCloseByOutsideClick";

type PropsType = {
  onChange: (value: string) => void;
  defaultValue?: string | null;
  placeholder?: string;
  options: Array<string>;
  className?: string;
  error?: boolean;
  disabled?: boolean;
};

export const BaseSelect: FC<PropsType> = ({
  defaultValue = "",
  placeholder,
  onChange,
  error,
  className = "",
  options,
  disabled,
  ...rest
}) => {
  const { toggle, toggleValue, isShowing } = useModal();
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (defaultValue || defaultValue === null) {
      setSelected(defaultValue ?? "");
    }
  }, [defaultValue]);

  const onHandleVariant = ({
    target,
  }: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const value = (target as HTMLElement).dataset.value?.trim() as string;

    setSelected(value);
    onChange(value);
    toggle();
  };

  const onHandleOpen = () => {
    if (!disabled) {
      toggle();
    }
  };

  const { ref } = useCloseByOutsideClick(() => toggleValue(false));

  return (
    <div
      className={clsx({
        "base-select": true,
        "base-select_required": error,
        [className]: !!className,
        "base-select_focus": isShowing,
      })}
      onClick={onHandleOpen}
      style={disabled ? { opacity: 0.5 } : undefined}
      ref={ref}
    >
      <div className="base-select__inner">
        <div className="base-select__input">
          {selected.length > 0 ? (
            selected
          ) : (
            <p className="base-select__placeholder">{placeholder}</p>
          )}
        </div>
        <div className="base-select__controls" onClick={onHandleOpen}>
          {!isShowing ? (
            <ArrowDownIcon onClick={onHandleOpen} color="#D4D3D9" />
          ) : (
            <TikIcon onClick={toggle} color="#D4D3D9" />
          )}
        </div>
      </div>
      <ul
        className="base-select__list"
        hidden={!isShowing}
        onClick={(e) => e.stopPropagation()}
      >
        {options.map((value, i) => (
          <li
            className="base-select__item"
            data-value={value}
            key={i}
            onClick={onHandleVariant}
          >
            {value}
          </li>
        ))}
      </ul>
    </div>
  );
};
