import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { BaseLoader } from "src/components/base/BaseLoader";
import { Message } from "./Message";
import { Image } from "src/content/Image";

import { useObserveChatInput } from "../hooks/useObserveChatInput";
import { useJwtPayload } from "src/hooks/useJwtPayload";
import { isUserMessage } from "../common/isUserMessage";
import {
  fetchAvailableAnswers,
  fetchMessages,
  fetchOnlyLastMessages,
  IMessage,
  selectMessage,
  selectPage,
  selectTotalPage,
  setMessages,
} from "src/redux/features/bot/botSlice";
import PlaceholderIcon from "./placeholder.svg";
import styles from "./ChatMessages.module.scss";

interface Props {
  messagesRef: any;
  inputRef: any;
}

export default forwardRef(function ChatMessages(
  { messagesRef, inputRef }: Props,
  _,
) {
  const { personId } = useJwtPayload();
  const [loading, setLoading] = useState(true);
  const [limit] = useState(50);

  const totalPages = useSelector(selectTotalPage);
  const messages = useSelector(selectMessage);
  const dispatch = useDispatch();
  const currentPage = useSelector(selectPage);
  const prevHeightRef = useRef<number>(0);
  const initRef = useRef<boolean>(false);

  // Меняет размер блока с сообщениями в зависимости от наполнения
  // (к примеру если там много чипсов, это не будет проблемой)
  useObserveChatInput(inputRef, messagesRef);

  function scrollToBottom(top: number) {
    messagesRef.current?.scrollTo({
      top,
    });
  }

  // Запрос данных
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch(fetchMessages({ page: 1, limit }) as any);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // Скролим при вниз чата первой инициализации компонента
  useEffect(() => {
    if (
      !messagesRef.current ||
      initRef.current === true ||
      messages.length === 0
    )
      return;
    scrollToBottom(messagesRef.current.scrollHeight);
    initRef.current = true;
  }, [messagesRef, initRef, messages]);

  // Запрос следующей страницы
  async function fetchNextPage(e: any) {
    if (!messagesRef.current) return;
    if (loading) return;

    const currentHeight = messagesRef.current.scrollHeight;
    prevHeightRef.current = currentHeight;

    if (
      messagesRef.current?.scrollTop === 0 &&
      totalPages &&
      totalPages > currentPage
    ) {
      setLoading((prev) => !prev);
      try {
        await dispatch(
          fetchMessages({ page: currentPage + 1, limit }) as any,
        ).unwrap();

        const newHeight = messagesRef.current.scrollHeight;
        const heightDiff = newHeight - prevHeightRef.current;
        scrollToBottom(heightDiff);
      } finally {
        setLoading((prev) => !prev);
      }
    }
  }

  // Transform to another structure
  const dateTimeFormatRef = useRef(
    new Intl.DateTimeFormat("ru-RU", {
      day: "numeric",
      month: "long",
    }),
  );

  const transformedMessages = useMemo(() => {
    const newMessages: Record<string, IMessage[]> = {};
    messages.forEach((message) => {
      const dateStr = dateTimeFormatRef.current.format(
        new Date(message.createdAt),
      );

      if (newMessages[dateStr]) {
        newMessages[dateStr].push(message);
      } else {
        newMessages[dateStr] = [message];
      }
    });

    return newMessages;
  }, [messages]);

  // Добавление сообщений в реальном времени
  useEffect(() => {
    const eventSource = new EventSource(
      process.env.REACT_APP_BACKEND_URL + "/api/bot/events/" + personId,
    );

    if (!eventSource) return;
    eventSource.onmessage = async (event) => {
      if (!event?.data) return;
      let data = null;
      try {
        data = JSON.parse(event.data);
      } catch (e) {
        console.error(e);
      }

      if (!data) return;

      await Promise.all([
        dispatch(fetchOnlyLastMessages({ page: 1, limit }) as any).unwrap(),
        dispatch(fetchAvailableAnswers() as any).unwrap(),
      ]).then(() => {
        setTimeout(() => {
          scrollToBottom(messagesRef.current.scrollHeight + 1000);
        }, 500);
      });
      // dispatch(setMessages(data));
    };

    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <div
      className={styles.ChatMessages}
      onScroll={fetchNextPage}
      ref={messagesRef}
    >
      <div
        className={clsx(
          styles.ChatMessages__Loader,
          loading ? styles.ChatMessages__Loader_Show : undefined,
        )}
      >
        <BaseLoader />
      </div>
      {(totalPages === currentPage || totalPages === 0) && !loading && (
        <Placeholder className={styles.ChatMessages__Placeholder} />
      )}
      <div className={styles.ChatMessages__List}>
        {Object.entries(transformedMessages).map(([date, messages]) => {
          return (
            <React.Fragment key={date}>
              <div className={styles.ChatMessages__ListDate}>{date}</div>
              {messages.map((message) => (
                <Message
                  className={
                    isUserMessage(message.from)
                      ? styles.ChatMessages__UserMessage
                      : undefined
                  }
                  data={message}
                  key={message.id}
                />
              ))}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
});

function Placeholder({ className }: { className: string }) {
  return (
    <div className={clsx(styles.Placeholder, className)}>
      <Image
        className={styles.Placeholder__Icon}
        src={PlaceholderIcon}
        height={56}
        width={56}
        alt="Bot logo"
      />
      <div className={styles.Placeholder__Description}>
        <h2 className={styles.Placeholder__Title}>Привет! Я - бот Тёрки!</h2>
        <p className={styles.Placeholder__Text}>
          Буду подбирать тебе пару для общения. Каждую неделю я буду находить
          для тебя двух новых собеседников.
        </p>
      </div>
    </div>
  );
}
