import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export interface ICategory {
  id: string;
  name: string;
  sign: string; // path to icon
}

export interface ICategoriesSliceState {
  hobbies: ICategory[];
  interests: ICategory[];
  needs: ICategory[];
  connections: ICategory[];
}

const initialState: ICategoriesSliceState = {
  hobbies: [],
  interests: [],
  needs: [],
  connections: [],
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    addCategory: (
      state,
      action: PayloadAction<{
        key: keyof ICategoriesSliceState;
        data: ICategory[];
      }>,
    ) => {
      state[action.payload.key] = action.payload.data;
    },
  },
});

export const { addCategory } = categoriesSlice.actions;

export const selectCategories = (state: RootState) => state.categories;
export const selectCategory =
  (key: keyof ICategoriesSliceState) => (state: RootState) =>
    state.categories[key];

export default categoriesSlice.reducer;
