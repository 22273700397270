import { z } from "zod";

import { nameValidate } from "./CommonFormSchema.schema";

export const RegistrationStep1Schema = z.object({
  firstName: nameValidate,
  lastName: nameValidate,
  email: z
    .string({ required_error: "обязательно" })
    .email({ message: "Incorrect e-mail" }),
  password: z
    .string({ required_error: "обязательно" })
    .min(8, "минимум 8 символов")
    .refine((value) => /\d/.test(value), "цифры")
    .refine((value) => /[a-zA-Z]/.test(value), "буквы"),
  wasAcceptedPolicy: z
    .boolean()
    .refine((value) => value, { message: "Примите условия использования" }),
  // wannaSignTerkaFull: z.boolean(),
  // isStore: z.boolean(),
});
