import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { CredentialsForm } from "src/layouts/CredentialsForm";
import { BaseTypography } from "src/components/base/BaseTypography";
import { BaseInputError } from "src/components/base/BaseInputError";
import { BaseLoader } from "src/components/base/BaseLoader";
import { BaseButton } from "src/components/base/BaseButton";
import { BaseInput } from "src/components/base/BaseInput";
import { Stack } from "src/components/base/Stack";

import { LINK_TO_AUTH_SHOP } from "../../../common/consts/shop";
import { useQueryIsStore } from "../../../hooks/useQuery";
import { SigninValidation } from "src/common/validation/index";
import { useAuth } from "src/hooks/useAuth";
import "./index.css";
import { useSignupForm } from "src/hooks/useSignupForm";
import { useDispatch } from "react-redux";
import { addToken } from "src/redux/features/user/userSlice";
import { RoutesEnum } from "src/common/enums";

type SigninType = {
  email: string;
  password: string;
};

export const Signin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { deleteSignupFormState } = useSignupForm();
  const { signin } = useAuth();
  const dispatch = useDispatch();
  const isStore = useQueryIsStore(); // hook говорит true если со стора

  const navigate = useNavigate();

  const form = useForm<SigninType>({
    resolver: yupResolver(SigninValidation),
  });

  const onSubmit = async (data: SigninType) => {
    try {
      setIsLoading(true);
      const accessToken = await signin(data);
      dispatch(addToken(accessToken));

      if (isStore) {
        window.location.href = LINK_TO_AUTH_SHOP;
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        console.log("urlParams: ", urlParams);
        if (urlParams.get("utm_source") === "telegram") {
          navigate(RoutesEnum.CHAT_BOT);
        } else {
          navigate(RoutesEnum.ACCOUNT);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const { errors } = form.formState;

  return (
    <CredentialsForm title="Вход">
      <div className="Signin">
        <form
          className="Signin__form"
          onSubmit={form.handleSubmit(onSubmit)}
          id="signin-form-id"
        >
          <div className="Signin__form-items">
            <Stack space={14}>
              <Stack space={10}>
                <BaseInput
                  className={`Signin__input`}
                  placeholder="Ваша электронная почта"
                  {...form.register("email")}
                />
                <BaseInputError<SigninType> errors={errors} field={"email"} />
              </Stack>
              <Stack space={10}>
                <BaseInput
                  className={`Signin__input`}
                  placeholder="Пароль"
                  type="password"
                  {...form.register("password")}
                />
                <BaseInputError<SigninType>
                  errors={errors}
                  field={"password"}
                />
              </Stack>
            </Stack>
            <BaseTypography variant="p" format="form-link">
              Еще нет аккаута?
              <Link
                className="Signin__link"
                onClick={deleteSignupFormState}
                to={RoutesEnum.SIGNUP}
              >
                Зарегистрироваться
              </Link>
            </BaseTypography>
          </div>
        </form>
        <div className="Signin__control">
          {isStore && <div></div>}

          {isLoading ? (
            <BaseLoader />
          ) : (
            <BaseButton
              className="Signin__control-button"
              variant="base-button-new-style-black"
              type="submit"
              form="signin-form-id"
            >
              Подтвердить
            </BaseButton>
          )}
          <Link className="Signin__control-link" to="/signin/reset-password">
            <BaseTypography variant="p" format="form-link">
              Восстановить пароль
            </BaseTypography>
          </Link>
        </div>
      </div>
    </CredentialsForm>
  );
};
