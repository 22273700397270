import { apiSlice as api } from "../features/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    contactsControllerGetAll: build.query<
      ContactsControllerGetAllApiResponse,
      ContactsControllerGetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/contacts`,
        params: {
          dateOrder: queryArg.dateOrder,
          scoreOrder: queryArg.scoreOrder,
          onlyFriends: queryArg.onlyFriends,
          limit: queryArg.limit,
          page: queryArg.page,
        },
      }),
    }),
    contactsControllerCreate: build.mutation<
      ContactsControllerCreateApiResponse,
      ContactsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/contacts`,
        method: "POST",
        body: queryArg.contactDto,
      }),
    }),
    contactsControllerLeaveComment: build.mutation<
      ContactsControllerLeaveCommentApiResponse,
      ContactsControllerLeaveCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/contacts/comment`,
        method: "PATCH",
        body: queryArg.commentDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as contacts };
export type ContactsControllerGetAllApiResponse = ListResponseVm;
export type ContactsControllerGetAllApiArg = {
  dateOrder?: "asc" | "desc";
  scoreOrder?: "asc" | "desc";
  onlyFriends?: boolean | null;
  limit: number;
  page: number;
};
export type ContactsControllerCreateApiResponse = unknown;
export type ContactsControllerCreateApiArg = {
  contactDto: ContactDto;
};
export type ContactsControllerUpdateStatusApiResponse = unknown;
export type ContactsControllerUpdateStatusApiArg = {
  applicationDto: ApplicationDto;
};
export type ContactsControllerLeaveCommentApiResponse = unknown;
export type ContactsControllerLeaveCommentApiArg = {
  commentDto: CommentDto;
};
export type PersonSocials = {
  id: string;
  telegram: string;
  vk: string | null;
  whatsapp: string | null;
  facebook: string | null;
  instagram: string | null;
};
export type AvatarVm = {
  /** Имя файла аватара */
  fileName: string;
  /** Путь к файлу аватара */
  path: string;
};
export type LatestMatchVm = {
  /** Статус последнего отзыва */
  status: "NEW" | "MET" | "DID_NOT_MET";
  /** Дата создания последнего отзыва */
  createdAt: string;
};
export type ContactVm = {
  /** Идентификатор контакта */
  id: string;
  /** Имя контакта */
  firstName: string;
  /** Фамилия контакта */
  lastName: string;
  /** Дата рождения контакта */
  birthday: string;
  /** Гендер контакта */
  gender:
    | "\u041C\u0443\u0436\u0441\u043A\u043E\u0439"
    | "\u0416\u0435\u043D\u0441\u043A\u0438\u0439";
  /** Город контакта */
  city: string;
  isAbroad: boolean;
  /** Кол-во матчей с контактом */
  matchCount: number;
  /** Социальные сети контакта */
  personSocials: PersonSocials;
  /** Аватар пользователя */
  avatar: AvatarVm | null;
  /** Комментарий к контакту */
  comment: string;
  /** Статус дружбы с контактом */
  friendship: "IDLE" | "PENDING" | "CONFIRMED" | "REQUESTED";
  /** Оценки двух последних матчей */
  lastScores: any[];
  /** Информация о последнем отзыве */
  latestMatch: LatestMatchVm;
};
export type ListResponseVm = {
  /** Общее количество контактов */
  count: number;
  /** Список контактов */
  items: ContactVm[];
  /** Общее количество страниц */
  totalPages: number;
};
export type ContactDto = {
  ownerId: string;
  contactId: string;
};
export type ApplicationDto = {
  contactId: string;
  status: "IDLE" | "PENDING" | "CONFIRMED" | "REQUESTED";
};
export type CommentDto = {
  comment: string;
  contactId: string;
};
