import { useSelector } from "react-redux";

import { ConfirmEmailModal } from "src/modals/ConfirmEmailModal";

import {
  ModalDataType,
  selectModal,
} from "src/redux/features/modal/modalSlice";

export default function ModalProvider() {
  const modal = useSelector(selectModal);
  return (
    <>
      {modal.type === "confirm-email" && (
        <ConfirmEmailModal
          data={modal.data as unknown as ModalDataType<"confirm-email">}
        />
      )}
    </>
  );
}
