import { z } from "zod";

import { socialsSchema } from "src/common/validation/socials-schema";

const personSocials = socialsSchema;

const personPolls = z.object({
  communication: z.object({
    proffessional: z.number(),
    romantic: z.number(),
    chat: z.number(),
  }),
  purpose: z.object({
    meeting: z.boolean(),
    events: z.boolean(),
    all: z.boolean(),
  }),
  aboutMe: z
    .string()
    .min(5, "надо рассказать подробнее")
    .max(500, "максимум 500 символов"),
  gender: z
    .object({
      male: z.boolean(),
      female: z.boolean(),
    })
    .refine((value) => value.male || value.female),
  age: z.object({
    from: z.number().optional(),
    till: z.number().optional(),
  }),
  hobbies: z.array(z.object({ id: z.string(), name: z.string() })).nonempty(),
  interests: z.array(z.object({ id: z.string(), name: z.string() })).nonempty(),
  needs: z.array(z.object({ id: z.string(), name: z.string() })).nonempty(),
  connections: z
    .array(z.object({ id: z.string(), name: z.string() }))
    .nonempty(),
});

export const FormAccountSchema = z.object({
  personPolls: personPolls,
  personSocials: personSocials,
});
