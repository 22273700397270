import styles from "./Label.module.scss";

export default function Label({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div className={styles.Label}>
      <p className={styles.Label__Text}>{title}</p>
      {children}
    </div>
  );
}
