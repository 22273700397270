import { createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

import { authProvider, SigninType } from "../api/auth";
import {
  delFromStorage,
  getFromStorage,
  setToStorage,
} from "../common/local-storage";
import { queryClient } from "src/api/request";
import { RoutesEnum } from "src/common/enums";
import { cookieValue, deleteCookie } from "../common/functions/cookie";
import { useDispatch } from "react-redux";
import { apiSlice } from "src/redux/features/api/apiSlice";
import { clearState } from "src/redux/features/bot/botSlice";

export const AuthContext = createContext<any>({
  signup: (data: any) => Promise.resolve(),
  signin: (data: SigninType, isLink: string) => Promise.resolve(null),
  signinFromStore: () => Promise.resolve(),
  handleAuth: (value: boolean) => {},
  signout: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
  authed: false,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authed, setAuthed] = useState<boolean>(
    () => !!getFromStorage("ACCESS_TOKEN"),
  );

  const handleAuth = (value: boolean) => setAuthed(() => value);

  const navigate = useNavigate();

  const signup = async (payload: any) => {
    try {
      const data = await authProvider.signup(payload);

      if (data.accessToken) {
        delFromStorage("CREDENTIALS");
        setToStorage("ACCESS_TOKEN", data.accessToken);
        setAuthed(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const signin = async (
    payload: SigninType,
    isLink = true,
  ): Promise<string | null> => {
    const data = await authProvider.signin(payload);

    if (data.accessToken) {
      delFromStorage("CREDENTIALS");
      setToStorage("ACCESS_TOKEN", data.accessToken);
      setAuthed(true);

      // if (isLink) {
      //   navigate("/account");
      // }

      return data.accessToken;
    }

    return null;
  };

  const signinFromStore = () => {
    //read cookies
    if (!("accessToken" in cookieValue())) {
      console.log("cookie not found");
      return null;
    }

    const token = cookieValue().accessToken;
    console.log("токен есть", token);

    //add to localstorage
    if (token) {
      console.log("сетим в акцес токен");
      delFromStorage("CREDENTIALS");
      setToStorage("ACCESS_TOKEN", token);
      setAuthed(true);

      // if (isLink) {
      //   navigate("/account");
      // }

      return token;
    }

    return null;
  };

  const dispatch = useDispatch();

  const signout = async (isFlag: boolean = true) => {
    try {
      await authProvider.signout();
    } catch (e) {
      console.error(e);
    } finally {
      queryClient.clear();
      delFromStorage("ACCESS_TOKEN");
      setAuthed(false);
      deleteCookie("accessToken");
      dispatch(apiSlice.util.resetApiState());
      dispatch(clearState());

      if (isFlag) {
        navigate(RoutesEnum.SIGNIN);
      }
    }
  };

  const refresh = async () => {
    try {
      const data = await authProvider.refresh();

      if (data.accessToken) {
        setToStorage("ACCESS_TOKEN", data.accessToken);
        setAuthed(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signup,
        signin,
        signinFromStore,
        handleAuth,
        signout,
        refresh,
        authed,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
