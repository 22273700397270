import score1 from "./score-1.svg";
import score2 from "./score-2.svg";
import score3 from "./score-3.svg";
import score4 from "./score-4.svg";
import score5 from "./score-5.svg";

export const scoresDictionary: Record<string, string> = {
  "1": score1,
  "2": score2,
  "3": score3,
  "4": score4,
  "5": score5,
};
