import { Link } from "react-router-dom";

import { BaseButton } from "../../components/base/BaseButton";
import { Button } from "src/components/base/Button";
import ProfileSelect from "../ProfileSelect";

import { useSignupForm } from "src/hooks/useSignupForm";
import { useAuth } from "../../hooks/useAuth";
import { useMe } from "../../api/hooks/personsHooks";
import "./index.scss";
import { RoutesEnum } from "src/common/enums";

type PropsType = {
  variant?: "account";
};

export const NavAuth = ({ variant }: PropsType) => {
  const { deleteSignupFormState } = useSignupForm();
  const { authed } = useAuth();
  const { data } = useMe();

  return !authed || !data ? (
    <>
      <Link className="nav-auth__account-link" to="/signin">
        {variant === "account" ? (
          <Button
            className={"nav-auth__account-button_" + variant}
            variant="outlined_nuar"
          >
            Войти
          </Button>
        ) : (
          <BaseButton
            className="nav-auth__account-button"
            variant="base-empty-btn"
          >
            Войти
          </BaseButton>
        )}
      </Link>
      <Link className="nav-auth__account-link" to={RoutesEnum.SIGNUP}>
        {variant === "account" ? (
          <Button
            className={"nav-auth__account-button_" + variant}
            variant="contained_nuar"
            onClick={deleteSignupFormState}
          >
            Регистрация
          </Button>
        ) : (
          <BaseButton
            className="nav-auth__account-button"
            variant="base-black-btn"
            onClick={deleteSignupFormState}
          >
            Регистрация
          </BaseButton>
        )}
      </Link>
    </>
  ) : (
    <div className="nav-auth__account-menu">
      <div className="nav-auth__account-menu-inner">
        <ProfileSelect />
      </div>
    </div>
  );
};
