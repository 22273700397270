import styles from "./PollHeader.module.scss";

interface IPollHeader {
  title: string;
  text?: string;
}

export default function PollHeader({ title, text }: IPollHeader) {
  console.count("PollHeader");
  return (
    <div className={styles.PollHeader}>
      <h2 className={styles.PollHeader__Title}>{title}</h2>
      {text && <p className={styles.PollHeader__Text}>{text}</p>}
    </div>
  );
}
