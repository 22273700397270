import { useCallback, useState } from "react";

import { Chips } from "./Chips";
import { Cards } from "./Cards";

import styles from "./Contacts.module.scss";

export interface IChipsFilter {
  dateOrder: "asc" | "desc" | undefined;
  scoreOrder: "asc" | "desc" | undefined;
  onlyFriends: boolean;
}

export default function ContactsPage() {
  const [filters, setFilters] = useState<IChipsFilter>({
    onlyFriends: false,
    scoreOrder: undefined,
    dateOrder: undefined,
  });

  const handleFilters = useCallback(
    (key: keyof IChipsFilter, val: IChipsFilter[keyof IChipsFilter]) => () => {
      setFilters((prev) => ({ ...prev, [key]: val }));
    },
    [],
  );

  return (
    <div className={styles.Contacts}>
      <h2 className={styles.Contacts__Title}>Список контактов</h2>
      <Chips onChange={handleFilters} filters={filters} />
      <Cards filters={filters} />
    </div>
  );
}
