import { useNavigate } from "react-router-dom";

import { BaseMenu } from "../../components/base/BaseMenu";

import { QueriesEnum, RoutesEnum } from "src/common/enums";
import { useAuth } from "../../hooks/useAuth";
import { useMe } from "../../api/hooks/personsHooks";

export default function ProfileSelect() {
  const { authed } = useAuth();
  const { data } = useMe();
  const navigate = useNavigate();

  return !authed || !data ? (
    <></>
  ) : (
    <BaseMenu
      options={[
        {
          value: "/account",
          label: "Мой профиль",
        },
        {
          value: RoutesEnum.CHAT_BOT,
          label: "Встречи",
        },
        {
          value: RoutesEnum.EVENTS + QueriesEnum.ALL_EVENTS,
          label: "Мероприятия",
        },
        {
          value: "/signout",
          label: "Выйти",
        },
      ]}
      onChange={({ value }) => {
        navigate(value);
      }}
      value={`${!!data?.firstName ? data?.firstName : "Новый"} ${
        !!data?.lastName ? data?.lastName : "пользователь"
      }`}
    />
  );
}
