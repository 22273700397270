import React, { useCallback, useRef } from "react";
import { CircularProgress } from "@mui/material";
import { useDropArea } from "react-use";
import toast from "react-hot-toast/headless";

import { BaseIcon } from "src/components/base/BaseIcon";

import { useWindowSize } from "src/hooks/useWindowSize";
import styles from "./ImageUploader.module.scss";
import clsx from "clsx";

type PropsType = {
  onChange: (file: File) => void;
  isLoading?: boolean;
  isBigImage?: boolean;
  placeholder?: string;
  className?: string;
};

const ERROR_FILE_NO_UPLOADED = "Файл не загружен";

const ERROR_FILE_EXT =
  "Неверный формат изображения, должен быть .jpg, .jpeg, .png";

const ERROR_FILE_SIZE = "Размер файла не должен превышать 15MB";

export function validateFileExt(file: File) {
  return (
    file &&
    !!["jpg", "jpeg", "png", "heic"].find((ext) => file.type.includes(ext))
  );
}

export function validateFileSize(file: File) {
  const fileSize = parseFloat((file.size / 1024).toFixed(2));
  const maxSize = 15360;
  return file && fileSize <= maxSize;
}

export function useFile(cb: (files: File[]) => void) {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const onChangeHandler = useCallback((e: any) => {
    e.preventDefault();
    let files: File[] | undefined;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (!files || files?.length === 0) {
      toast.error(ERROR_FILE_NO_UPLOADED);
      return;
    }

    if (!validateFileSize(files[0])) {
      toast.error(ERROR_FILE_SIZE);
      return;
    }

    if (!validateFileExt(files[0])) {
      toast.error(ERROR_FILE_EXT);
      return;
    }

    console.log("PREV_FILES: ", files[0]);

    cb(files);

    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  }, []);

  const onUploadByClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  return {
    inputFileRef,
    onChangeHandler,
    onUploadByClick,
  };
}

export default function ImageUploader({
  isLoading,
  onChange,
  isBigImage,
  placeholder,
  className,
}: PropsType) {
  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const [bond] = useDropArea({
    onFiles: (files) => {
      if (isLoading) return;

      if (!files || files?.length === 0) {
        toast.error(ERROR_FILE_NO_UPLOADED);
        return;
      }

      if (!validateFileExt(files[0])) {
        toast.error(ERROR_FILE_EXT);
        return;
      }

      if (!validateFileSize(files[0])) {
        toast.error(ERROR_FILE_SIZE);
        return;
      }

      onChange(files[0]);
    },
  });

  const onChangeHandler = (e: any) => {
    e.preventDefault();
    let files: File[] | undefined;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (!files || files?.length === 0) {
      toast.error(ERROR_FILE_NO_UPLOADED);
      return;
    }

    if (!validateFileSize(files[0])) {
      toast.error(ERROR_FILE_SIZE);
      return;
    }

    if (!validateFileExt(files[0])) {
      toast.error(ERROR_FILE_EXT);
      return;
    }

    onChange(files[0]);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  const windowSize = useWindowSize();

  return (
    <div
      className={clsx(styles.ImageUploader, className, {
        [styles["ImageUploader--isBig"]]: isBigImage,
      })}
      {...bond}
    >
      <input
        className={styles.ImageUploader__input}
        disabled={isLoading}
        onChange={onChangeHandler}
        multiple={true}
        type="file"
        accept="image/jpg, image/jpeg, image/png, image/heic"
      />
      {isLoading ? (
        <CircularProgress
          className={styles.ImageUploader__loader}
          color="inherit"
        />
      ) : (
        <div className={styles.ImageUploader__inner}>
          <div className={styles.ImageUploader__content}>
            <BaseIcon
              className={styles.ImageUploader__icon}
              viewBox="0 0 45 45"
              height="45"
              width="45"
              icon="SEEDS_BAG"
            />
            {windowSize[0] < 768 && "Обзор"}
          </div>
          <div className={styles.ImageUploader__format}>jpg, jpeg, png</div>
          <div className={styles.ImageUploader__title}>
            {!isBigImage
              ? placeholder ?? "Рекомендуемое разрешение: 390 х 240 px"
              : placeholder ?? "Рекомендуемое разрешение: 1440 х 500 px"}
          </div>
          <div className={styles.ImageUploader__note}>
            {/* {windowSize[0] > 767 && */}
            "Перетащите или кликните для загрузки файла"
          </div>
        </div>
      )}
    </div>
  );
}
