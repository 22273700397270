import { useEffect, useRef } from "react";
import clsx from "clsx";

import styles from "./Input.module.scss";

export function TextArea({
  className = "",
  onChange,
  value,
  error,
  disableBreak,
  ...rest
}: {
  className?: string;
  placeholder: string;
  maxLength?: number;
  max?: number;
  onChange: (newValue: string) => void;
  onBlur?: () => void;
  value: string;
  error: boolean;
  disabled?: boolean;
  id?: string;
  disableBreak?: boolean;
}) {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.height = "0px";
    ref.current.style.height = ref.current.scrollHeight + "px";
  }, [ref.current, value]);

  return (
    <textarea
      className={clsx({
        [styles.TextArea]: true,
        [styles.Input]: true,
        [styles.Input_Error]: error,
        [className]: !!className,
      })}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && disableBreak) {
          e.preventDefault();
          return;
        }
      }}
      value={value}
      ref={ref}
      rows={1}
      {...rest}
    />
  );
}
// <div className={styles.TextAreaWrapper}>
//   <p className={styles.TextAreaWrapper__Counter}>
//     <span className={styles.TextAreaWrapper__Value}>{value.length}</span>
//     <span className={styles.TextAreaWrapper__Divider}>/</span>
//     <span className={styles.TextAreaWrapper__Limit}>200</span>
//   </p>
// </div>
