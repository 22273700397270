import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { resetState } from "src/redux/features/user/userSlice";
import { useAuth } from "../../hooks/useAuth";

export default function Signout() {
  const { signout } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await signout();
      dispatch(resetState());
    })();
  }, []);

  return <Outlet />;
}
