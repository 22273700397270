import clsx from "clsx";

import { capitalizeFirstLetter, getAgeWithDeclension } from "./ContactBio.lib";
import styles from "./ContactBio.module.scss";

interface IContactBioProps {
  className?: string;
  data: {
    firstName: string;
    lastName: string;
    birthday: string;
    city: string;
    isAbroad: boolean;
  };
}

export default function ContactBio({ className, data }: IContactBioProps) {
  return (
    <div className={clsx(styles.ContactBio, className)}>
      <div className={styles.ContactBio__Name}>
        {capitalizeFirstLetter(data.firstName)}{" "}
        {capitalizeFirstLetter(data.lastName)}
      </div>
      <div className={styles.ContactBio__Info}>
        {getAgeWithDeclension(data.birthday)},{" "}
        {data.isAbroad ? "Город за границей" : data.city}
      </div>
    </div>
  );
}
