import { scoresDictionary } from "src/common/scores";
import { Image } from "src/content/Image";

import styles from "./ChatScoreButton.module.scss";
import clsx from "clsx";

interface ChatScoreButtonProps {
  onClick: () => void;
  checked?: boolean;
  value: string;
}

export default function ChatScoreButton({
  checked,
  value,
  ...rest
}: ChatScoreButtonProps) {
  return (
    <div
      className={clsx({
        [styles.ChatScoreButton]: true,
        [styles.ChatScoreButton_Active]: checked,
      })}
      {...rest}
    >
      <Image src={scoresDictionary[value]} alt="Score" height={55} width={55} />
    </div>
  );
}
