import { useDispatch } from "react-redux";
import { delFromStorage } from "src/common/local-storage";
import { resetForm } from "src/redux/features/poll/pollSlice";

export function useSignupForm() {
  const dispatch = useDispatch();
  const deleteSignupFormState = () => {
    dispatch(resetForm());
    delFromStorage("INFORMATION");
  };

  return {
    deleteSignupFormState,
  };
}
