import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import { RegistrationLayout } from "./layouts/RegistrationLayout";
import { RegistrationStep1 } from "./RegistrationStep1";
import { RegistrationStep2 } from "./RegistrationStep2";
import { RegistrationStep3 } from "./RegistrationStep3";
import { RegistrationStep4 } from "./RegistrationStep4";
import { RegistrationStep5 } from "./RegistrationStep5";
import { RegistrationStep6 } from "./RegistrationStep6";

import { selectStep } from "src/redux/features/poll/pollSlice";
import { RoutesEnum } from "src/common/enums";

export const SignupRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RegistrationLayout />}>
        <Route index element={<SignupSteps />} />
      </Route>
      <Route path="/*" element={<Navigate to={RoutesEnum.SIGNUP} />} />
    </Routes>
  );
};

function SignupSteps() {
  const { current } = useSelector(selectStep);

  console.count("SignupSteps");

  switch (current) {
    case 1:
      return <RegistrationStep1 />;
    case 2:
      return <RegistrationStep2 />;
    case 3:
      return <RegistrationStep3 />;
    case 4:
      return <RegistrationStep4 />;
    case 5:
      return <RegistrationStep5 />;
    case 6:
      return <RegistrationStep6 />;
    default:
      return null;
  }
}
