import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { TelegramOutlinedIcon, TikIcon } from "../../components/base/BaseIcons";
import { BurgerIcon } from "../../components/base/BaseIcons";
import { Container } from "src/layouts/Container";
import { NavAuth } from "../NavAuth";
import { Logo } from "../Logo/Logo";

import imageSemkaBurgerMenu from "../../common/images/image-semka-burger-menu.webp";
import { useWindowSize } from "../../hooks/useWindowSize";
import { LINK_TO_BOT } from "src/common/consts/bot";
import { useModal } from "../../hooks/useModal";
import { useMe } from "src/api/hooks/personsHooks";
import { QueriesEnum, RoutesEnum } from "src/common/enums";
import { BaseIcon } from "src/components/base/BaseIcon";
import "./index.scss";
import clsx from "clsx";
import { LINK_TO_AUTH_SHOP } from "../../common/consts/shop";
import { useSignupForm } from "src/hooks/useSignupForm";

type MenuItem = {
  title: string;
  href: string;
};

const menuItems: Array<MenuItem> = [
  // { title: "Для кого это?", href: "/#for-who" },
  // { title: "Тарифы", href: "/#tariffs" },
  // // { title: "Отзывы", href: "#quotes" },
  // { title: "Поддержка", href: "/#support" },
  // { title: "Контакты", href: "/#contacts" },
  {
    title: "Мероприятия",
    href: RoutesEnum.EVENTS + QueriesEnum.ALL_EVENTS,
  },
  { title: "Магазин", href: LINK_TO_AUTH_SHOP },
];

type PropsType = {
  variant?: "account";
  isWhiteHeader?: boolean;
};

export const NavBar = ({ variant, isWhiteHeader }: PropsType) => {
  const { deleteSignupFormState } = useSignupForm();
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    const root = document.querySelector("#root") as HTMLElement;

    if (isShowing && root) {
      document.body.style.overflowY = "hidden";
      document.body.style.position = "fixed";
      root.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
      document.body.style.position = "unset";
      root.style.overflowY = "unset";
    }

    return () => {
      document.body.style.overflowY = "unset";
      document.body.style.position = "unset";
      root.style.overflowY = "unset";
    };
  }, [isShowing]);

  const [width] = useWindowSize();
  const { data: me } = useMe();

  return (
    <div className={clsx(["NavBar", "NavBar_" + variant])}>
      {/*<Container>*/}
      {/*</Container>*/}
      <ul className="NavBar__inner">
        <li
          className={clsx([
            "NavBar-socials",
            "NavBar__socials",
            "NavBar__socials_" + variant,
          ])}
        >
          <a
            className="NavBar-socials__telegram-link"
            href={LINK_TO_BOT + "?start=" + me?.id}
            target="_blank"
            rel="noreferrer"
          >
            <TelegramOutlinedIcon
              className="NavBar-socials__telegram-icon"
              color="#ffffff"
            />
          </a>
          <div className="NavBar-socials__contacts">
            <a
              className="NavBar-socials__contact"
              href="tel:+7 952 055 98 46"
              target="_blank"
              rel="noreferrer"
            >
              +7 952 055 98 46
            </a>
            <br />
            <a
              className="NavBar-socials__contact"
              href="mailto:bazar@terkatalk.ru"
              target="_blank"
              rel="noreferrer"
            >
              bazar@terkatalk.ru
            </a>
          </div>
        </li>
        <li
          className={clsx([
            "NavBar-logo",
            "NavBar__logo",
            "NavBar__logo_" + variant,
          ])}
        >
          <Logo variant={isWhiteHeader ? "white" : "black"} />
        </li>
        <li className="NavBar-menu NavBar__menu">
          {width <= 1439 && isShowing && (
            <Logo className="NavBar-menu__logo" variant="white" />
          )}
          {width <= 1439 && (
            <BurgerMenu
              className="NavBar-menu__burger-menu-icon"
              isShowing={isShowing}
              toggle={toggle}
            />
          )}
          <ul
            className={[
              "NavBar-menu__list",
              isShowing ? "NavBar-menu__list_open" : "",
            ]
              .join(" ")
              .trim()}
          >
            {menuItems.map(({ title, href }, i) =>
              title === "Мероприятия" ? (
                <li
                  className="NavBar-menu__item"
                  key={i}
                  onClick={() => (isShowing ? toggle() : () => {})}
                >
                  <Link
                    className={clsx(
                      ["NavBar-menu__link", "NavBar-menu__link_" + variant],
                      { ["whiteHeader"]: isWhiteHeader },
                    )}
                    to={href}
                  >
                    {title}
                  </Link>
                </li>
              ) : (
                <li
                  className="NavBar-menu__item"
                  key={i}
                  onClick={() => (isShowing ? toggle() : () => {})}
                >
                  <a
                    className={clsx(
                      ["NavBar-menu__link", "NavBar-menu__link_" + variant],
                      { ["whiteHeader"]: isWhiteHeader },
                    )}
                    href={href}
                  >
                    {title}
                  </a>
                </li>
              ),
            )}
            {width <= 1439 ? (
              <>
                <li className="NavBar-menu__item">
                  <img src={imageSemkaBurgerMenu} alt="Семечка" />
                </li>
                <li className="NavBar-menu__item">
                  <Link
                    className="NavBar-menu__link"
                    to={RoutesEnum.SIGNUP}
                    onClick={deleteSignupFormState}
                  >
                    Регистрация
                  </Link>
                </li>
                <li className="NavBar-menu__item">
                  <Link className="NavBar-menu__link" to="/signin">
                    Войти
                  </Link>
                </li>
                <li className="NavBar-menu__socials">
                  <a
                    className="NavBar-menu__socials-link"
                    href="tel:+7 952 055 98 46"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +7 952 055 98 46
                  </a>
                  <a
                    className="NavBar-menu__socials-link"
                    // href={LINK_TO_BOT}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TelegramOutlinedIcon
                      className="NavBar-menu__socials-telegram"
                      color="#ffffff"
                    />
                  </a>
                  <a
                    className="NavBar-menu__socials-link"
                    href="mailto:bazar@terkatalk.ru"
                    target="_blank"
                    rel="noreferrer"
                  >
                    bazar@terkatalk.ru
                  </a>
                </li>
              </>
            ) : null}
          </ul>
        </li>
        <li className="NavBar__controls">
          <NavAuth variant={variant} />
        </li>
      </ul>
    </div>
  );
};

const BurgerMenu = ({
  className = "",
  isShowing,
  toggle,
}: {
  className?: string;
  isShowing: boolean;
  toggle: () => void;
}) => {
  const isEvents =
    location.href.includes("/?tab") || location.href.includes("/event-list");

  return (
    <div
      className={[
        "NavBar-menu__burger",
        className,
        isEvents ? "NavBar-menu__burger_events" : undefined,
      ]
        .join(" ")
        .trim()}
      onClick={() => toggle()}
    >
      {isShowing ? (
        <TikIcon className="NavBar-menu__burger-icon" color="#FFFFFF" />
      ) : isEvents ? (
        <BaseIcon
          className={clsx([
            "NavBar-menu__burger-icon",
            "NavBar-menu__burger-icon_open",
          ])}
          width="38"
          height="16"
          viewBox="0 0 38 16"
          fill="none"
          icon="BURGER"
        />
      ) : (
        <BurgerIcon className="NavBar-menu__burger-icon" color="#FFFFFF" />
      )}
    </div>
  );
};
