import { useQuery } from "react-query";
import { getFromStorage } from "src/common/local-storage";
import { request } from "../request";

export type PersonType = {
  id: string;
  tgId: string;
  username: string;
  firstName: string;
  lastName: string;
  favouriteHobbies: Array<string>;
  interestingAreas: Array<string>;
  liveInKaliningrad: boolean;
  gender: string;
  age: number;
  score: Array<number>;
  city: string;
  otherCity: boolean;
  aboutMe: string;
  isStopped: boolean;
  social: string;
  email: string;
  createdAt: string;
  birthday: string;
  readyToChatOnline: boolean;
  // matches: Array<MatchType>;
};

type CheckEmailResponseType = {
  isExists: boolean;
};

type SendCodeToEmailType = {
  email: string;
};

type ConfirmCodeType = {
  email: string;
  code: string;
};

export const getMe = async () => {
  const { data } = await request<PersonType>({
    url: "/persons/me",
  });

  return data;
};

export const resetPassword = async (payload: Pick<PersonType, "email">) => {
  const { data } = await request<void>({
    url: "/persons/actions/reset_password",
    method: "POST",
    data: payload,
  });

  return data;
};

export const updatePassword = async (payload: {
  code: string;
  password: string;
}) => {
  const { data } = await request<void>({
    url: "/persons/actions/update_password/" + payload.code,
    method: "POST",
    data: { newPassword: payload.password },
  });

  return data;
};

export const checkEmail = async (payload: { email: string }) => {
  const { data } = await request<CheckEmailResponseType>({
    url: "/auth/validate/email",
    method: "POST",
    data: payload,
  });

  return data;
};

export const sendCodeToEmail = async (payload: SendCodeToEmailType) => {
  const { data } = await request<boolean>({
    url: "/auth/actions/send-code",
    method: "POST",
    data: payload,
  });

  return data;
};

export const confirmCode = async (payload: ConfirmCodeType) => {
  const { data } = await request<boolean>({
    url: "/auth/actions/confirm-code",
    method: "POST",
    data: payload,
  });

  return data;
};

export const checkAccessToken = async () => {
  const { data } = await request<{ isValid: boolean }>({
    url: "auth/validate/auth",
    data: {
      accessToken: getFromStorage("ACCESS_TOKEN") ?? "",
    },
    method: "POST",
  });

  return data;
};

//HOOKS
export const useMe = () =>
  useQuery<PersonType>(["user"], async () => await getMe(), {
    enabled: !!getFromStorage("ACCESS_TOKEN"),
    // staleTime: 5000,
    // cacheTime: 5000,
    // notifyOnChangeProps: "tracked",
    // refetchOnWindowFocus: true,
  });
