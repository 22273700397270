import { PollLayout } from "../layouts/PollLayout";
import { PollHeader } from "../components/PollHeader";
import { CategoryForm } from "../components/CategoryForm";

import { RegistrationStep2Schema } from "../RegistrationStep2Schema";

const FORM_ID = "RegistrationStep2";

export default function RegistrationStep2() {
  console.count("RegistrationStep2");
  return (
    <PollLayout
      formId={FORM_ID}
      head={
        <PollHeader
          title="Чем вы занимаетесь?"
          text="Выберите минимум 1 вариант"
        />
      }
      form={
        <CategoryForm
          validationSchema={RegistrationStep2Schema}
          category="hobbies"
          nextStep={3}
          formId={FORM_ID}
        />
      }
    />
  );
}
