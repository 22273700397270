import { request } from "./request";
import {
  GetAllMatchesDto,
  PaginatedDto,
  PaginatedRequestDto,
} from "./commons/types";
import { useQuery } from "react-query";
import { MatchType } from "./hooks/matchesHooks";
import { getFromStorage } from "../common/local-storage";
import { getMe, PersonType } from "./hooks/personsHooks";

export type ProfileType = {
  id: string;
  birthday: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  isNotifyByEvents?: boolean;
  isOld?: boolean;
  personSocials: {
    telegram: string;
    vk: string;
    whatsapp: string;
    facebook: string;
    instagram: string;
  };
  personPolls: {
    aboutMe: string;
    age: {
      from: number;
      till: number;
    };
    communication: {
      proffessional: number;
      romantic: number;
      chat: number;
    };
    gender: {
      male: boolean;
      female: boolean;
    };
    purpose: {
      meeting: boolean;
      events: boolean;
      all: boolean;
    };
    hobbies: { id: string; name: string }[];
    interests: { id: string; name: string }[];
    needs: { id: string; name: string }[];
    connections: { id: string; name: string }[];
  };
};

export const getProfile = async () => {
  const { data } = await request<ProfileType>({
    url: "/persons/profile",
    method: "GET",
  });

  return data;
};

export const getAllowedParticipants = async () => {
  const { data } = await request<number | undefined>({
    url: "/persons/counts",
    method: "GET",
  });

  return data;
};

export const updateProfile = async (payload: any) => {
  const { data } = await request({
    url: "/persons/profile",
    method: "PUT",
    data: payload,
  });
  return data;
};

export const updateEventsInProfile = async (payload: {
  isNotifyByEvents: boolean | undefined;
}) => {
  const {} = await request({
    url: "persons/switch-notify-by-events",
    method: "PATCH",
    data: payload,
  });
};

//HOOKS

export const useGetProfile = () =>
  useQuery<any>(["profile"], async () => await getProfile(), {
    enabled: !!getFromStorage("ACCESS_TOKEN"),
    staleTime: 5000,
    cacheTime: 5000,
    notifyOnChangeProps: "tracked",
    refetchOnWindowFocus: true,
  });

export const useGetAllowedParticipants = () =>
  useQuery<number | undefined>(["participant-count"], () =>
    getAllowedParticipants(),
  );
