import { createContext, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { ProfileCard } from "./ProfileCard";
import { Image } from "src/content/Image";

import ChatBotAvatarIcon from "./chat-bot-avatar.svg";
import ExternalLinkIcon from "./external-link.svg";
import { scoresDictionary } from "src/common/scores";
import { isUserMessage } from "../../common/isUserMessage";
import { IMessage } from "src/redux/features/bot/botSlice";
import styles from "./Message.module.scss";

interface MessageProps {
  className?: string;
  data: IMessage;
}

export default function Message({ className, data }: MessageProps) {
  switch (data.type) {
    case "PROFILE":
      return (
        <>
          <BaseMessage wrapperClassName={className} data={data}>
            <BaseMessage.Inner>
              <BaseMessage.Avatar />
              <BaseMessage.Text />
            </BaseMessage.Inner>
          </BaseMessage>

          <BaseMessage wrapperClassName={className} data={data}>
            <BaseMessage.Inner>
              <BaseMessage.Avatar />
            </BaseMessage.Inner>
            <BaseMessage.Attach>
              <ProfileCard data={data} />
            </BaseMessage.Attach>
            <BaseMessage.Time />
          </BaseMessage>
        </>
      );
    case "COMMON":
      return (
        <BaseMessage wrapperClassName={className} data={data}>
          <BaseMessage.Inner>
            <BaseMessage.Avatar />
            <BaseMessage.Text />
          </BaseMessage.Inner>
          <BaseMessage.Time />
        </BaseMessage>
      );
    case "LINKED":
      return (
        <BaseMessage wrapperClassName={className} data={data}>
          <BaseMessage.Inner>
            <BaseMessage.Avatar />
            <BaseMessage.Text />
          </BaseMessage.Inner>
          <BaseMessage.Attach>
            <Link className={styles.Attach} to="/contacts">
              <div className={styles.Attach__Text}>Список контактов</div>
              <img
                className={styles.Attach__Icon}
                loading="lazy"
                height={16}
                width={16}
                src={ExternalLinkIcon}
              />
            </Link>
          </BaseMessage.Attach>
          <BaseMessage.Time />
        </BaseMessage>
      );
    case "SCORE":
      return (
        <div className={className}>
          <Image
            src={scoresDictionary[data.text]}
            alt="Score"
            height={55}
            width={55}
          />
        </div>
      );
    default:
      return null;
  }
}

const MessageContext = createContext(
  {} as IMessage & { wrapperClassName?: string },
);
const useMessageContext = () => useContext(MessageContext);

interface BaseMessageProps {
  children: React.ReactNode;
  wrapperClassName?: string;
  data: IMessage;
}

function BaseMessage({ children, wrapperClassName, data }: BaseMessageProps) {
  const memoizedValue = useMemo(() => ({ ...data, wrapperClassName }), [data]);
  return (
    <MessageContext.Provider value={memoizedValue}>
      <ChatMessage>{children}</ChatMessage>
    </MessageContext.Provider>
  );
}

function ChatMessage({ children }: { children: React.ReactNode }) {
  const { from, wrapperClassName } = useMessageContext();
  const isUser = isUserMessage(from);
  return (
    <div
      className={clsx(wrapperClassName, {
        [styles.BaseMessage]: true,
        [styles.BaseMessage_System]: !isUser,
        [styles.BaseMessage_User]: isUser,
      })}
    >
      {children}
    </div>
  );
}

function ChatMessageContent({ children }: { children?: React.ReactNode }) {
  const { from } = useMessageContext();
  const isUser = isUserMessage(from);
  return (
    <div
      className={clsx(styles.BaseMessage__Content, {
        [styles.BaseMessage__Content_System]: !isUser,
        [styles.BaseMessage__Content_User]: isUser,
      })}
    >
      {children}
    </div>
  );
}

function ChatMessageAvatar() {
  const { from } = useMessageContext();
  const isUser = isUserMessage(from);
  return isUser ? null : (
    <img
      className={styles.BaseMessage__Icon}
      loading="lazy"
      height={28}
      width={28}
      src={ChatBotAvatarIcon}
    />
  );
}

function ChatMessageText() {
  const { text } = useMessageContext();
  return <div className={styles.BaseMessage__Text}>{text}</div>;
}

function ChatMessageAttach({ children }: { children?: React.ReactNode }) {
  return <div className={styles.BaseMessage__Attach}>{children}</div>;
}

function ChatMessageTime() {
  const { createdAt, from } = useMessageContext();

  let now;
  let h;
  let m;

  const isSystem = from === "SYSTEM";

  if (isSystem) {
    now = new Date(createdAt);
    h = now.getHours();
    m = now.getMinutes().toString().padStart(2, "0");
  }

  return isSystem ? (
    <div className={styles.BaseMessage__Time}>
      {h}:{m}
    </div>
  ) : null;
}

BaseMessage.Inner = ChatMessageContent;
BaseMessage.Avatar = ChatMessageAvatar;
BaseMessage.Text = ChatMessageText;
BaseMessage.Attach = ChatMessageAttach;
BaseMessage.Time = ChatMessageTime;
