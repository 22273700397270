import { BaseSlider } from "src/components/base/BaseSlider";

import styles from "./CategorySelector.module.scss";
import clsx from "clsx";

type PropsType = {
  value?: number;
  onChange: (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => void;
  label: string;
  step?: number;
  min?: number;
  max?: number;
  classNameLeftLabel?: string;
  classNameRightLabel?: string;
  classNameWrapper?: string;
  disabled?: boolean;
};

export function CategorySelector({
  value,
  onChange,
  label,
  step = 1,
  min = 0,
  max = 10,
  classNameLeftLabel,
  classNameRightLabel,
  classNameWrapper,
  disabled,
}: PropsType) {
  const LeftLabelClassName = clsx({
    [styles.CategorySelector__Label]: true,
    [styles.CategorySelector__Label_Zero]: min === 0,
    classNameLeftLabel: !!classNameLeftLabel,
  });

  const RightLabelClassName = clsx([
    styles.CategorySelector__Label,
    classNameRightLabel,
  ]);

  const className = clsx({
    [styles.CategorySelectorDisabled]: disabled,
  });

  return (
    <div className={clsx(styles.CategorySelector, classNameWrapper)}>
      <div className={styles.CategorySelector__Labels}>
        <div className={LeftLabelClassName}>{value === min ? "" : min}</div>
        <div className={RightLabelClassName}>{value === max ? "" : max}</div>
      </div>
      <BaseSlider
        className={className}
        getAriaLabel={() => label}
        value={value}
        onChange={onChange}
        valueLabelDisplay="on"
        step={step}
        min={min}
        max={max}
        disabled={disabled}
      />
    </div>
  );
}
