import { z } from "zod";

const tgRegexp = /^(?:@?[a-zA-Z0-9_]+|https?:\/\/t\.me\/[a-zA-Z0-9_]+)$/;
const vkRegex = /^https:\/\/.*/;
const whatsappPhoneRegex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
const facebookRegex = /^https:\/\/.*/;
const instagramRegex = /^https:\/\/.*/;

export const socialsSchema = z.object({
  telegram: z.string().regex(tgRegexp),
  vk: z
    .string()
    .optional()
    .refine((value) => !value || vkRegex.test(value), {
      message:
        "Некорректная ссылка на VK. Убедитесь, что это ссылка формата https://vk.com/username",
    }),
  whatsapp: z
    .string()
    .optional()
    .refine((value) => !value || whatsappPhoneRegex.test(value), {
      message:
        "Некорректный номер телефона для WhatsApp. Убедитесь, что он указан в формате +7234567890",
    }),
  facebook: z
    .string()
    .optional()
    .refine((value) => !value || facebookRegex.test(value), {
      message:
        "Некорректная ссылка на Facebook. Убедитесь, что это ссылка формата https://facebook.com/username",
    }),
  instagram: z
    .string()
    .optional()
    .refine((value) => !value || instagramRegex.test(value), {
      message:
        "Некорректная ссылка на Instagram. Убедитесь, что это ссылка формата https://instagram.com/username",
    }),
});
