import { Outlet, useParams } from "react-router-dom";

import { Logo } from "src/content/Logo/Logo";

import backgroundImage from "./horizontal-lines-1920.webp";
import styles from "./RegistrationLayout.module.scss";
import { useSelector } from "react-redux";
import { selectStep } from "src/redux/features/poll/pollSlice";

export default function RegistrationLayout() {
  const { current } = useSelector(selectStep);
  const isLast = current === 6;
  const backgroundPosition = isLast ? "top" : "center";
  const backgroundSize = isLast ? "contain" : "cover";
  return (
    <main
      className={styles.RegistrationLayout}
      style={{
        background: `${backgroundPosition} / ${backgroundSize} no-repeat url(${backgroundImage})`,
      }}
    >
      <Logo />
      <Outlet />
    </main>
  );
}
