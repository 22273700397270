import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

interface IGetAllMessagesResponse<T> {
  totalPages: number;
  items: T[];
}

export interface IMessage {
  id: string;
  type: "COMMON" | "LINKED" | "PROFILE" | "SCORE";
  from: "SYSTEM" | "USER";
  personId: string;
  text: string;
  link?: string;
  linkText?: string;
  interlocutorId: null | string;
  interlocutor: {
    isAbroad: boolean;
    avatar: {
      fileName: string;
      path: string;
    } | null;
    id: string;
    firstName: string;
    lastName: string;
    birthday: string;
    gender: string;
    city: string;
    personPolls: {
      aboutMe: string;
      hobbies: Array<{
        id: string;
        name: string;
      }>;
      communication: {
        romantic: number;
      };
    };
    personSocials: {
      telegram: string;
      facebook: string | null;
      instagram: string | null;
      vk: string | null;
      whatsapp: string;
    };
    matchesPair: {
      matches: {
        id: string;
        createdAt: string;
        status: string;
        feedbacks: {
          score: string;
          createdAt: string; // Для сортировки последних фидбеков
        };
      };
    };
  };
  createdAt: string;
  updatedAt: string;
  contextId: string;
}

export interface IReplayPayload {
  nextQuestionID: string;
  answerIDs: Array<string>;
}

export interface IAnswer {
  id: string;
  text: string;
  type: "COMMON" | "SCORE" | "LINK";
  value: string | null;
  variant: "SINGLE" | "MULTIPLE";
  nextQuestionID: string;
  questionID: string;
}

interface IQuestion {
  id: string;
  order: number;
  text: string;
  last: boolean;
  type: "COMMON" | "LINKED" | "PROFILE" | "SCORE" | "REGEXP";
  scenarioID: string;
  answers: Array<IAnswer>;
}

interface IReplyResponse {
  messages: Array<IMessage>;
  question: IQuestion;
}

interface State {
  messages: IMessage[];
  totalPages: null | number;
  answers: null | Array<IAnswer>;
  page: number;
  telegramLink: null | string;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

export const fetchMessages = createAsyncThunk(
  "bot/fetchMessages",
  async ({ page, limit }: { page: number; limit: number }, { extra }) => {
    const result = await (extra as any).req({
      url: `/bot?page=${page}&limit=${limit}`,
    });
    return result.data as IGetAllMessagesResponse<IMessage>;
  },
);

export const fetchOnlyLastMessages = createAsyncThunk(
  "bot/fetchOnlyLastMessages",
  async ({ page, limit }: { page: number; limit: number }, { extra }) => {
    const result = await (extra as any).req({
      url: `/bot?page=${page}&limit=${limit}`,
    });
    return result.data as IGetAllMessagesResponse<IMessage>;
  },
);

export const replyQuestion = createAsyncThunk(
  "bot/replyQuestion",
  async (data: IReplayPayload, { extra }) => {
    const result = await (extra as any).req({
      url: "/bot/reply",
      method: "POST",
      data,
    });
    return result.data as IReplyResponse;
  },
);

export const fetchAvailableAnswers = createAsyncThunk(
  "bot/fetchAvailableAnswers",
  async (_, { extra }) => {
    const result = await (extra as any).req({
      url: "/bot/last",
    });
    return result.data;
  },
);

const initialState: State = {
  messages: [],
  totalPages: null,
  page: 1,
  answers: null,
  telegramLink: null,
  loading: "idle",
};

const botSlice = createSlice({
  name: "bot",
  initialState,
  reducers: {
    clearState(state) {
      state.messages = [];
      state.totalPages = null;
      state.page = 1;
      state.answers = null;
      state.telegramLink = null;
    },
    setMessages(state, action) {
      state.messages = state.messages.concat(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      if (
        state.messages.length === 0 ||
        (state.messages.length > 0 && action.meta.arg.page === 1)
      ) {
        state.messages = action.payload.items;
        state.totalPages = action.payload.totalPages;
        state.page = action.meta.arg.page;
      } else {
        state.messages = action.payload.items.concat(state.messages);
        state.page = action.meta.arg.page;
      }
    });
    builder.addCase(replyQuestion.fulfilled, (state, action) => {
      state.messages = state.messages.concat(action.payload.messages);
      state.answers = action.payload.question.answers;
    });
    builder.addCase(fetchAvailableAnswers.fulfilled, (state, action) => {
      state.answers = action.payload.answers;
      state.telegramLink = action.payload.telegramLink
        ? action.payload.telegramLink
        : null;
    });
    builder.addCase(fetchOnlyLastMessages.fulfilled, (state, action) => {
      state.messages = action.payload.items;
      state.totalPages = action.payload.totalPages;
      state.page = action.meta.arg.page;
    });
  },
});

export const selectMessage = (root: RootState) => root.bot.messages;
export const selectAnswers = (root: RootState) => root.bot.answers;
export const selectTelegramLink = (root: RootState) => root.bot.telegramLink;
export const selectTotalPage = (root: RootState) => root.bot.totalPages;
export const selectPage = (root: RootState) => root.bot.page;
export const { clearState, setMessages } = botSlice.actions;
export default botSlice.reducer;
