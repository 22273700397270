import { useCallback, useState } from "react";

import { BaseIcon } from "src/components/base/BaseIcon";
import { Input } from "../";

import { IInputProps } from "../Input";
import styles from "./InputPassword.module.scss";

interface IProps extends Omit<IInputProps, "className"> {}

export default function InputPassword(props: IProps) {
  const [type, setType] = useState<"text" | "password">("password");
  const toggleType = useCallback(() => {
    setType((prev) => (prev === "password" ? "text" : "password"));
  }, []);

  const isMoreThan0 = props.value.length > 0;
  const isMoreThan8 = props.value.length >= 8;
  const hasNumber = /\d/.test(props.value);
  const hasLetter = /[a-zA-Z]/.test(props.value);

  const pv = !isMoreThan0 ? "#C7C7C7" : isMoreThan8 ? "#16A249" : "#E90000";
  const nv = !isMoreThan0 ? "#C7C7C7" : hasNumber ? "#16A249" : "#E90000";
  const lv = !isMoreThan0 ? "#C7C7C7" : hasLetter ? "#16A249" : "#E90000";

  return (
    <div className={styles.InputPassword}>
      <div className={styles.InputPassword__Field}>
        <Input className={styles.InputPassword__Input} type={type} {...props} />
        <BaseIcon
          className={styles.InputPassword__Toggle}
          onClick={toggleType}
          viewBox="0 0 22 22"
          height="22"
          width="22"
          icon={type === "password" ? "EYE_OPEN" : "EYE_CLOSE"}
        />
      </div>
      <div className={styles.InputPassword__Validation}>
        <Validator color={pv} text="8+ символов" />
        <Validator color={nv} text="Цифры" />
        <Validator color={lv} text="Буквы" />
      </div>
    </div>
  );
}

function Validator({ text, color }: { text: string; color: string }) {
  return (
    <div className={styles.Validator}>
      <svg
        className={styles.Validator__Icon}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={color}
          d="M13.3641 4.23431C13.6765 4.54673 13.6765 5.05327 13.3641 5.36569L6.96412 11.7657C6.6517 12.0781 6.14517 12.0781 5.83275 11.7657L2.63275 8.56569C2.32033 8.25327 2.32033 7.74673 2.63275 7.43431C2.94517 7.1219 3.4517 7.1219 3.76412 7.43431L6.39844 10.0686L12.2328 4.23431C12.5452 3.9219 13.0517 3.9219 13.3641 4.23431Z"
        />
      </svg>

      <p className={styles.Validator__Text}>{text}</p>
    </div>
  );
}
