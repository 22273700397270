import { ContactVm } from "src/redux/requests/contacts.slice";
import TelegramIcon from "./telegram.svg";
import InstagramIcon from "./instagram.svg";
import FacebookIcon from "./facebook.svg";
import VKIcon from "./vk.svg";
import WhatsAppIcon from "./whatsapp.svg";
import CopyIcon from "./copy.svg";
import styles from "./ContactSocials.module.scss";
import clsx from "clsx";
import { showToastSuccess } from "src/common/functions/showToastSuccess";

interface IContactSocialsProps {
  className?: string;
  data: {
    personSocials: {
      telegram: string;
      vk: string | null;
      whatsapp: string | null;
      facebook: string | null;
      instagram: string | null;
    };
  };
}

const socials: Record<string, string> = {
  telegram: TelegramIcon,
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  vk: VKIcon,
  whatsapp: WhatsAppIcon,
};

export default function ContactSocials({
  className,
  data,
}: IContactSocialsProps) {
  const personSocials = Object.entries(data.personSocials).filter(
    ([_, v]) => v,
  );
  return (
    <div className={clsx(styles.ContactSocials, className)}>
      {personSocials.map(([k, v]) => (
        <div
          className={styles.Contact}
          key={k}
          onClick={() => {
            navigator.clipboard.writeText(v!);
            showToastSuccess(`${k} скопирован`);
          }}
        >
          <img
            className={styles.Contact__Icon}
            height={18}
            width={18}
            alt="Social icon"
            loading="lazy"
            src={socials[k]}
          />
          <div className={styles.Contact__Value}>{v}</div>
          <img
            className={styles.Contact__Button}
            height={18}
            width={18}
            loading="lazy"
            src={CopyIcon}
            alt="Copy icon"
          />
        </div>
      ))}
    </div>
  );
}
