enum LSKeys {
  ACCESS_TOKEN = "ACCESS_TOKEN",
  CREDENTIALS = "CREDENTIALS",
  INFORMATION = "INFORMATION",
}

export type LSKeysType = keyof typeof LSKeys;
export type UserInStorageType = {
  email: string;
  password: string;
  wasAcceptedPolicy: boolean;
  wannaSignTerkaFull?: boolean;
};

export const getFromStorage = <T,>(key: LSKeysType): T => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const setToStorage = <T,>(key: LSKeysType, data: T) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const delFromStorage = (key: LSKeysType) => {
  localStorage.removeItem(key);
};
