import { useEffect } from "react";

import { CHAT_CONTROL_BAR_ID } from "../constants";

export function useObserveChatInput(inputRef: any, messagesRef: any) {
  useEffect(() => {
    if (!inputRef.current && !messagesRef.current) return;
    const d = document.getElementById(CHAT_CONTROL_BAR_ID)!;
    messagesRef.current.style.setProperty(
      "--input-height",
      `${d.offsetHeight}px`,
    );

    const observer = new MutationObserver((_1, _) => {
      const d = document.getElementById(CHAT_CONTROL_BAR_ID)!;
      messagesRef.current.style.setProperty(
        "--input-height",
        `${d.offsetHeight}px`,
      );
    });

    observer.observe(d, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, [inputRef, messagesRef]);
}
