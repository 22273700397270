import { BaseIcon } from "src/components/base/BaseIcon";
import { Button } from "src/components/base/Button";
import { Modal } from "src/components/base/Modal";

import styles from "./CreatogEventCredsModal.module.scss";
import { BaseInput } from "src/components/base/BaseInput";
import { BaseInputError } from "src/components/base/BaseInputError";
import { DeepRequired, FieldErrorsImpl } from "react-hook-form";
import { useState } from "react";

type PropsType = {
  isOpen: boolean;
  toggle: () => void;
  onClickSubmit: () => void;
  onHandleState: (key: keyof CreatorCredsType, value: string) => void;
  errors: FieldErrorsImpl<DeepRequired<CreatorCredsType>>;
  state: CreatorCredsType;
};

export type CreatorCredsType = {
  creatorTg: string;
  creatorMobile: string;
};
export default function CreatogEventCredsModal({
  isOpen,
  toggle,
  onClickSubmit,
  errors,
  onHandleState,
  state,
}: PropsType) {
  const handleInputChange = (event: string) => {
    let formattedValue = "+";
    if (event?.length > 0) {
      formattedValue += event?.substring(0, 1);
    }
    if (event?.length > 1) {
      formattedValue += " (" + event?.substring(1, 4);
    }
    if (event?.length > 4) {
      formattedValue += ") " + event?.substring(4, 7);
    }
    if (event?.length > 7) {
      formattedValue += "-" + event?.substring(7, 9);
    }
    if (event?.length > 9) {
      formattedValue += "-" + event?.substring(9, 11);
    }

    return formattedValue;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className={styles.CreatogEventCredsModal}>
        <button className={styles.cross} onClick={toggle} />
        <h2 className={styles.title}>Мероприятие на модерации</h2>
        <p className={styles.note}>введите номер телефона и TG для связи</p>
        <div className={styles.inputContainer}>
          <div>
            <BaseInput
              placeholder="Телефон"
              typeIconForModal="PHONE"
              onChange={(e) =>
                onHandleState(
                  "creatorMobile",
                  e.target.value
                    .replace(/[^0-9]/g, "")
                    .replace(/^([^7])/, "7")
                    .substring(0, 11),
                )
              }
              value={handleInputChange(state.creatorMobile)}
              autoComplete="tel"
              name="phoneCreator"
              id="phoneCreator"
            />
            <BaseInputError field="creatorMobile" errors={errors} />
          </div>
          <div>
            <BaseInput
              placeholder="TG"
              typeIconForModal="TG"
              onChange={(e) => onHandleState("creatorTg", e.target.value)}
              value={state.creatorTg}
              autoComplete="tg"
              name="telegramCreator"
              id="telegramCreator"
            />
            <BaseInputError field="creatorTg" errors={errors} />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button variant={"outlined_nuar"} fullWidth onClick={toggle}>
            Отмена
          </Button>

          <Button variant={"contained_nuar"} fullWidth onClick={onClickSubmit}>
            Отправить
          </Button>
        </div>
      </div>
    </Modal>
  );
}
