import { BaseLoader } from "src/components/base/BaseLoader";

import styles from "./FullScreenLoader.module.scss";

export default function FullScreenLoader() {
  return (
    <div className={styles.FullScreenLoader}>
      <BaseLoader />
    </div>
  );
}
