import { useEffect } from "react";
import { RoutesEnum } from "src/common/enums";

// Нужен для того, чтобы компенсировать ширину скроллбара
// и избавить от сдвига макета
export function useScrollbarPadding() {
  useEffect(() => {
    const body = document.body;
    const resizeObserver = new ResizeObserver(() => {
      const hasVerticalScrollbar = body.scrollHeight > window.innerHeight;
      !hasVerticalScrollbar &&
      window.location.pathname !== RoutesEnum.HOME &&
      window.location.pathname !== RoutesEnum.CHAT_BOT
        ? (body.style.paddingRight = "11px")
        : (body.style.paddingRight = "unset");
    });

    resizeObserver.observe(body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
}
