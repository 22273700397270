import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { InputPassword } from "../components/Input/InputPassword";
import { PollHeader } from "../components/PollHeader";
import { PollLayout } from "../layouts/PollLayout";
import { Checkbox } from "../components/Checkbox";
import { Input } from "../components/Input";

import {
  useAuthControllerSendConfirmationCodeMutation,
  useAuthControllerValidateEmailMutation,
} from "src/redux/requests/auth.slice";
import { RegistrationStep1Schema } from "../RegistrationStep1Schema";
import { showToastError } from "src/common/functions/showToastError";
import { useFormState } from "src/hooks/useFormState";
import { openModal } from "src/redux/features/modal/modalSlice";
import {
  IPollIntro,
  selectForm,
  addForm,
  incrementStep,
} from "src/redux/features/poll/pollSlice";
import styles from "./RegistrationStep1.module.scss";
import { store } from "src/redux/store";

const FORM_ID = "RegistrationStep1";
const validationSchema = RegistrationStep1Schema;

export default function RegistrationStep1() {
  console.count("RegistrationStep1");
  return (
    <PollLayout
      formId={FORM_ID}
      head={<PollHeader title="Регистрация" />}
      form={<RegistrationForm formId={FORM_ID} />}
    />
  );
}

interface IRegistrationForm {
  className?: string;
  formId: string;
}

function RegistrationForm({ className, formId }: IRegistrationForm) {
  const [checkEmail] = useAuthControllerValidateEmailMutation();
  const [sendCode, { isLoading }] =
    useAuthControllerSendConfirmationCodeMutation();

  const dispatch = useDispatch();
  const onSubmit = async (data: IPollIntro) => {
    if (isLoading) return;
    if (
      data.email.toLowerCase() ===
      store.getState().poll.form.intro.email.toLowerCase()
    ) {
      dispatch(incrementStep());
      return;
    }

    try {
      const result = await checkEmail({
        validateEmailDto: {
          email: data.email,
        },
      }).unwrap();

      if (result.isExists) {
        showToastError("Пользователь с таким email уже существует");
        return;
      }
    } catch (e) {
      console.error("checkEmail: ", e);
      showToastError("Что-то пошло не так!");
      return;
    }

    try {
      await sendCode({
        sendCodeDto: {
          email: data.email,
        },
      }).unwrap();
    } catch (e) {
      console.error("sendCode: ", e);
      showToastError("Не удалось отправить код!");
      return;
    }

    dispatch(addForm({ key: "intro", data }));
    dispatch(
      openModal({
        type: "confirm-email",
        data: {
          email: data.email,
        },
      }),
    );
  };

  const defaultValues = useSelector(selectForm("intro")) as IPollIntro;
  const { onChange, getError, onBlur, state, form } = useFormState<IPollIntro>({
    validationSchema,
    defaultValues,
  });

  return (
    <form
      className={clsx(styles.RegistrationForm, className)}
      onSubmit={form.handleSubmit(onSubmit)}
      id={formId}
    >
      <Input
        onChange={onChange("firstName")}
        onBlur={onBlur("firstName")}
        error={getError("firstName")}
        value={state.firstName}
        placeholder="Имя"
        maxLength={80}
      />
      <Input
        onChange={onChange("lastName")}
        onBlur={onBlur("lastName")}
        error={getError("lastName")}
        value={state.lastName}
        placeholder="Фамилия"
        maxLength={80}
      />
      <div className={styles.RegistrationFormInput}>
        <Input
          onChange={onChange("email")}
          onBlur={onBlur("email")}
          error={getError("email")}
          value={state.email}
          placeholder="Ваша электронная почта"
          maxLength={80}
        />
        <p className={styles.RegistrationFormInput__Issue}>
          Укажите действующий адрес, вышлем на него код подтверждения
        </p>
      </div>
      <div>
        <InputPassword
          onChange={onChange("password")}
          onBlur={onBlur("password")}
          error={getError("password")}
          value={state.password}
          placeholder="Пароль"
          maxLength={80}
        />
      </div>
      <div
        className={clsx(
          styles.RegistrationFormCheckbox,
          styles.RegistrationForm__Checkbox,
        )}
      >
        <Checkbox
          onChange={onChange("wasAcceptedPolicy")}
          checked={state.wasAcceptedPolicy}
          error={getError("wasAcceptedPolicy")}
        />
        <p className={styles.RegistrationFormCheckbox__Text}>
          Я соглашаюсь с{" "}
          <a
            className={styles.RegistrationFormCheckbox__Link}
            href="/privacy.pdf"
            target="_blank"
          >
            правилами сайта на обработку персональных данных
          </a>
        </p>
      </div>
    </form>
  );
}
