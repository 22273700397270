import { PollLayout } from "../layouts/PollLayout";
import { PollHeader } from "../components/PollHeader";
import { CategoryForm } from "../components/CategoryForm";
import { RegistrationStep4Schema } from "../RegistrationStep4Schema";

const FORM_ID = "RegistrationStep4";

export default function RegistrationStep4() {
  console.count("RegistrationStep4");
  return (
    <PollLayout
      formId={FORM_ID}
      head={
        <PollHeader
          title="Что вы хотите от Тёрки?"
          text="Выберите минимум 1 вариант"
        />
      }
      form={
        <CategoryForm
          validationSchema={RegistrationStep4Schema}
          category="needs"
          nextStep={5}
          formId={FORM_ID}
        />
      }
    />
  );
}
