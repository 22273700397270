import clsx from "clsx";
import styles from "./Input.module.scss";
import { forwardRef } from "react";

export interface IInputProps {
  className?: string;
  onChange: (value: string) => void;
  value: string;
  error?: string;
  placeholder?: string;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void; // Добавлено onPaste
  maxLength?: number;
  type?: "password" | "email" | "text" | "tel";
  tabIndex?: number;
  disabled?: boolean;
  id?: string;
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ className, onChange, error, onPaste, ...rest }, ref) => {
    return (
      <input
        ref={ref} // Передаем ref
        className={clsx(
          styles.Input,
          error ? styles.Input_Error : undefined,
          className,
        )}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onPaste={onPaste} // Обрабатываем событие вставки
        autoComplete="off"
        {...rest}
      />
    );
  },
);

Input.displayName = "Input"; // Для отладки в React DevTools

export default Input;
